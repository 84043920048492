import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  children?: ReactNode;
}
interface State {
  hasError: boolean;
}
const ErrorComponent = () => {
  return (
    <div className="errorpage__center">
      <div className="errorpage">
        <div className="container main-content center-text">
          <div className="main-content-inners ">
            <div className="title">
              <h1><b style={{fontSize: 60}}>Ошибка</b></h1>
            </div>
            <div className="text">
              <p>Извините!<br/> Произошла ошибка...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorComponent /> : children;
  }
};