import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="errorpage">
      <div className="container">
        <div className="main-content-inners ">
          <div className="title">
            <h1>ошибка <b>404</b></h1>
          </div>
          <div className="center">
            <p>Извините! Страница, которую Вы ищете, не может быть найдена...</p>
          </div>
        </div>
      </div>
    </div>
  )
};
export default NotFound;