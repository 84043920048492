import { useNavigate } from "react-router-dom";
import { useCreateRegistrationFormRequestMutation } from "services/api/api";
import { RegistrationFormRequest } from "services/api/types/FormRequest";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import MaskedInput from "react-text-mask";
import { useState } from 'react';
import ModalPopup from "shared/ModalPopup";
import FormResult from "shared/FormResult";
import { parse } from 'date-fns';    

const Registration = () => {
    const navigate = useNavigate();
	const [createFormRequest] = useCreateRegistrationFormRequestMutation();
    const phoneNumberMask = [
        "+",
        "7",
        " ",
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/
    ];
    const dateMask = [
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ];
    const validationsSchema = yup.object().shape({
        first_name: yup.string()
            .min(3, 'Слишком короткое имя')
            .matches(/[а-я,А-Я]/, 'Имя должно содержать только русские буквы')
            .matches(/^([^0-9]*)$/gm, "Имя должно содержать только буквы")
            .required('Обязательно к заполнению'),
        last_name: yup.string()
            .min(3, 'Слишком короткое значение')
            .matches(/[а-я,А-Я]/, 'Фамилия должна содержать только русские буквы')
            .matches(/^([^0-9]*)$/gm, "Фамилия должна содержать только буквы")
            .required('Обязательно к заполнению'),
        patronymic: yup.string()
            .min(3, 'Слишком короткое отчество')
            .matches(/[а-я,А-Я]/, 'Отчество должно содержать только русские буквы')
            .matches(/^([^0-9]*)$/gm, "'Отчество должно содержать только буквы"),
        birth_date: yup.date()
            .transform((value, originalValue, context) => parse(originalValue, 'dd.MM.yyyy', new Date()))
            .typeError("Введите дату в формате 12.12.1912"),
        phone: yup.string()
            .required('Обязательно к заполнению')
            .transform(value => value.replace(/[^\d]/g, ''))
            .min(11, "Введите корректный номер"),
        email: yup.string()
            .email('Введите верный email')
            .required('Обязательно к заполнению'),
        password: yup.string()
            .required('Обязательно к заполнению')
            .min(8, 'Минимальная длина пароля 8 символов')
            .matches(/^[^а-яА-ЯёЁ]*$/, "Пароль не должен содержать русские буквы")
            .matches(/[0-9]/, 'Пароль должен содержать хотя бы одну цифру')
            .matches(/[a-z,A-Z]/, 'Пароль должен содержать хотя бы одну букву'),
        re_password: yup.string()
            .required('Обязательно к заполнению')
            .oneOf([yup.ref("password")], "Пароли не совпадают"),
        newsletter: yup.bool()
            .oneOf([true], 'Дайте согласие на рассылку'),
        check: yup.bool()
            .oneOf([true], 'Дайте согласие на обработку персональных данных'),
    });
    const [resultShow, setResultShow] = useState(false);
    const [resultText, setResultText] = useState({
        status: '',
        text: ''
    });
    return (
        <>
            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    patronymic: '',
                    birth_date: '',
                    phone: '',
                    email: '',
                    password: '',
                    re_password: '',
                    newsletter: true,
                    check: true
                }}
                validateOnBlur
                onSubmit={async ({first_name, last_name, patronymic, birth_date, phone, email, password, re_password}, {resetForm}) => {
                    let body: RegistrationFormRequest = {
                        first_name,
                        last_name,
                        phone,
                        email,
                        password,
                        re_password
                    }
                    if (patronymic.length) body.patronymic = patronymic;
                    if (birth_date.length) {
                        let parts = birth_date.split('.');
                        body.birth_date = `${parts[2]}-${parts[1]}-${parts[0]}`;
                    }
                    let res = await createFormRequest({ body });
                    if (res['error']) {
                        setResultShow(true);
                        setResultText({
                            status: 'Ошибка',
                            text: res['error'].data 
                                ?  res['error'].data[0] === 'Unable to create account.' 
                                    ? 'Данные уже использованы для регистрации'
                                    : Object.values(res['error'].data).map((el: string[]) => el.join('\n')).join('\n') 
                                : 'Попробуйте позже'
                        });
                    } else {
                        navigate('/register');
                    }
                }}
                validationSchema={validationsSchema}
            >
                {({
                    handleSubmit,
                    isSubmitting
                }) => (
                    <Form className="form profile-form profile-form--registration" onSubmit={handleSubmit}>
                        <div className="form__field">
                            <Field
                                type="text"
                                name="first_name"
                                placeholder="ИМЯ*" />
                            <ErrorMessage name="first_name" component="span" className="form__error" />
                        </div>
                        <div className="form__field">
                            <Field
                                type="text"
                                name="last_name"
                                placeholder="ФАМИЛИЯ*" />
                            <ErrorMessage name="last_name" component="span" className="form__error" />
                        </div>
                        <div className="form__field">
                            <Field
                                type="text"
                                name="patronymic"
                                placeholder="ОТЧЕСТВО" />
                            <ErrorMessage name="patronymic" component="span" className="form__error" />
                        </div>
                        <div className="form__field form__field--date">
                            <Field name="birth_date">
                                {({ field }) => <MaskedInput
                                    {...field}
                                    mask={dateMask}
                                    placeholder="ДАТА РОЖДЕНИЯ"
                                    type="text"
                                />
                                }
                            </Field>
                            <ErrorMessage name="birth_date" component="span" className="form__error" />
                        </div>
                        <div className="form__field">
                            <Field name="phone">
                                {({ field }) => <MaskedInput
                                    {...field}
                                    mask={phoneNumberMask}
                                    placeholder="ТЕЛЕФОН*"
                                    type="tel"
                                />
                                }
                            </Field>
                            <ErrorMessage name="phone" component="span" className="form__error" />
                        </div>
                        <div className="form__field">
                            <Field
                                type="email"
                                name="email"
                                placeholder="E-MAIL*" /> 
                            <ErrorMessage name="email" component="span" className="form__error" />
                        </div>
                        <div className="form__field">
                            <Field
                                type="password"
                                name="password"
                                placeholder="ПАРОЛЬ*" /> 
                            <ErrorMessage name="password" component="span" className="form__error" />
                        </div>
                        <div className="form__field">
                            <Field
                                type="password"
                                name="re_password"
                                placeholder="ПОВТОРИТЕ ПАРОЛЬ*" /> 
                            <ErrorMessage name="re_password" component="span" className="form__error" />
                        </div>
                        <div>
                            <div className="form__check">
                                <Field
                                    className="visually-hidden"
                                    name="newsletter"
                                    type="checkbox"
                                    id="newsletter" />
                                <label htmlFor="newsletter"><span>Согласие на рассылку</span></label>
                            </div>
                            <div className="form__check">
                                <Field
                                    className="visually-hidden"
                                    name="check"
                                    type="checkbox"
                                    id="about_check" />
                                <label htmlFor="about_check"><span>Я согласен с <a href={process.env.PUBLIC_URL + '/politic.pdf?v=1'} target="_blank" rel="noreferrer">политикой конфиденциальности</a> и <a href={process.env.PUBLIC_URL + '/personal.pdf?v=1'} target="_blank" rel="noreferrer">обработки персональных данных</a></span></label>
                            </div>
                        </div>
                        <button type="submit" disabled={isSubmitting} className="form__btn btn gradient">зарегистрироваться</button>
                        <ErrorMessage name="check" component="span" className="form__error form__error--static" />
                    </Form>
                )}
            </Formik>
            {resultShow && <ModalPopup setShow={setResultShow} addedClass="form_result">
                <FormResult status={resultText.status} text={resultText.text}/>
            </ModalPopup>}
        </>
    );
}
export default Registration;