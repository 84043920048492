import HeadTags from "shared/HeadTags";
import { useNavigate } from "react-router-dom";
import { useCreateQuestionFormRequestMutation } from "services/api/api";
import { QuestionFormRequest } from "services/api/types/FormRequest";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import MaskedInput from "react-text-mask";
import { Link } from "react-router-dom";
import { useGetFAQListQuery } from "services/api/api";
import LoadingBlock from "shared/LoadingBlock";
import { FAQItem } from "services/api/types/News";
import ModalPopup from "shared/ModalPopup";
import FormResult from "shared/FormResult";
import ym from 'react-yandex-metrika';

const FaqForm = ({showResult}) => {
    const [createQuestionFormRequest] = useCreateQuestionFormRequestMutation();
    const phoneNumberMask = [
        "+",
        "7",
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/
    ];
    const validationsSchema = yup.object().shape({
        name: yup.string()
            .min(3, 'Слишком короткое имя')
            .matches(
                /[а-я,А-Я]/,
                'Имя должно содержать только русские буквы'
            )
            .matches(/^([^0-9]*)$/gm, "'Имя должно содержать только буквы")
            .required('Обязательно к заполнению'),
        phone: yup.string()
            .required('Обязательно к заполнению')
            .transform(value => value.replace(/[^\d]/g, ''))
            .min(11, "Введите корректный номер"),
        question: yup.string()
            .test(
                'nourl',
                'Текст сообщения введен некорректно',
                (value) => {
                    return !value.match(/(www\.)|(https?:)|(\.ru)|(\.com)|(\.рф)/)
                }
            )
            .required('Обязательно к заполнению'),
        email: yup.string()
            .required('Обязательно к заполнению')
            .email('Введите верный email'),
        check: yup.bool()
            .oneOf([true], 'Дайте согласие на обработку персональных данных'),
    });
    return (
        <Formik
            initialValues={{
                name: '',
                phone: '',
                email: '',
                question: '',
                check: true
            }}
            validateOnBlur
            onSubmit={async ({ name, phone, question, email }, {resetForm}) => {
                const body: QuestionFormRequest = {
                    name,
                    phone,
                    question,
                    email,
                    form_source_url: `${window.location}`
                };
                let res = await createQuestionFormRequest({ body });
                if (res['error']) {
                    showResult({
                        status: 'Ошибка!',
                        text: `Попробуйте позже`
                    });
                } else {
                    showResult({
                        status: 'Спасибо!',
                        text: `Ваша заявка отправлена`
                    });
                    resetForm();
                }
            }}
            validationSchema={validationsSchema}
        >
            {({
                handleSubmit,
                isSubmitting
            }) => (
                <Form className="about-form" onSubmit={handleSubmit}>
                    <p className="about-form__title h2">остались вопросы?</p>
                    <div className="about-form__grid form">
                        <div className="form__field">
                            <Field
                                type="text"
                                name="name"
                                placeholder="ваше имя" />
                            <ErrorMessage name="name" component="span" className="form__error" />
                        </div>
                        <div className="form__field">
                            <Field name="phone">
                                {({ field }) => <MaskedInput
                                    {...field}
                                    mask={phoneNumberMask}
                                    placeholder="телефон"
                                    type="tel"
                                />
                                }
                            </Field>
                            <ErrorMessage name="phone" component="span" className="form__error" />
                        </div>
                        <div className="form__field">
                           <Field
                            type="email"
                            name="email"
                            placeholder="E-mail" /> 
                            <ErrorMessage name="email" component="span" className="form__error" />
                        </div>
                        <div className="form__textarea">
                            <label htmlFor="about_text">Вопрос</label>
                            <Field
                                as="textarea"
                                name="question"
                                id="about_text" />
                            <ErrorMessage name="question" component="span" className="form__error" />
                        </div>
                        <div className="form__check">
                            <Field
                                className="visually-hidden"
                                name="check"
                                type="checkbox"
                                checked
                                id="about_check" />
                            <label htmlFor="about_check"><span>Я согласен с <Link to="">политикой конфиденциальности и обработки персональных данных</Link></span></label>
                        </div>
                        <button type="submit" disabled={isSubmitting} className="form__btn btn gradient">Отправить</button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
const FaqPage = ({ setPageClass }) => {
    const { data, isLoading } = useGetFAQListQuery();
    const navigate = useNavigate();
    const [resultShow, setResultShow] = useState(false);
    const [resultText, setResultText] = useState({
        status: '',
        text: ''
    });

    const showResult = ({status, text}) => {
        setResultShow(true);
        setResultText({status, text});
    };

    useEffect(() => {
        setPageClass('support-page');
        ym('hit', '/poddergka');
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="faq-page">
            <HeadTags
                title='Вопрос - Ответ - Елена Полянская'
                metaDescription='Поддержка. Ответы на популярные вопросы. Задайте свой вопрос.'
            />
            <section className="container faq">
                <ul className="breadcrumbs">
                    <li><Link to="/">главная</Link></li>
                    <li><span>Поддержка</span></li>
                </ul>
                <h1 className="h2">Техническая поддержка</h1>
                <LoadingBlock isLoading={isLoading}>
                    {data
                        ? <div className="faq-list">
                            {data.map((item: FAQItem) => {
                                return (
                                    <details className="faq-list__item" key={item.id}>
                                        <summary>{item.question}</summary>
                                        <p>{item.answer}</p>
                                    </details>
                                );
                            })}
                        </div>
                        : <p>Раздел в разработке.</p>
                    }
                </LoadingBlock>
                <FaqForm showResult={showResult}/>
                {resultShow && <ModalPopup setShow={setResultShow} addedClass="form_result">
                    <FormResult status={resultText.status} text={resultText.text}/>
                </ModalPopup>}
            </section>
        </div>
    )
}
export default FaqPage;