import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useCreateSupportFormRequestMutation } from "services/api/api";
import { FormRequest } from "services/api/types/FormRequest";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import MaskedInput from "react-text-mask";
import ModalPopup from "shared/ModalPopup";
import FormResult from "shared/FormResult";
import { User } from 'services/api/types/User';

const AccountForm = ({ data }: { data: User}) => {
    const [createFormRequest] = useCreateSupportFormRequestMutation();
    const phoneNumberMask = [
        "+",
        "7",
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/
    ];
    const validationsSchema = yup.object().shape({
        name: yup.string()
            .min(3, 'Слишком короткое имя')
            .matches(
                /[а-я,А-Я]/,
                'Имя должно содержать только русские буквы'
            )
            .matches(/^([^0-9]*)$/gm, "'Имя должно содержать только буквы")
            .required('Обязательно к заполнению'),
        phone: yup.string()
            .required('Обязательно к заполнению')
            .transform(value => value.replace(/[^\d]/g, ''))
            .min(11, "Введите корректный номер"),
        comment: yup.string()
            .test(
                'nourl',
                'Текст сообщения введен некорректно',
                (value) => {
                    return !value.match(/(www\.)|(https?:)|(\.ru)|(\.com)|(\.рф)/)
                }
            )
            .required('Обязательно к заполнению'),
        email: yup.string()
            .email('Введите верный email')
            .required('Обязательно к заполнению'),
        check: yup.bool()
            .oneOf([true], 'Дайте согласие на обработку персональных данных'),
    });
    const [resultShow, setResultShow] = useState(false);
    const [resultText, setResultText] = useState({
        status: '',
        text: ''
    });

    return (
        <>
            <Formik
                initialValues={{
                    name: data.first_name,
                    phone: data.phone,
                    email: data.email,
                    comment: '',
                    check: true
                }}
                validateOnBlur
                onSubmit={async ({ name, phone, comment, email }, { resetForm }) => {
                    const body: FormRequest = {
                        name,
                        phone,
                        comment,
                        email,
                        form_source_url: `${window.location}`
                    };
                    let res = await createFormRequest({ body });
                    if (res['error']) {
                        setResultShow(true);
                        setResultText({
                            status: 'Ошибка!',
                            text: `Попробуйте позже`
                        });
                    } else {
                        resetForm();
                        setResultShow(true);
                        setResultText({
                            status: 'Спасибо!',
                            text: `Ваша заявка отправлена`
                        });
                    }
                }}
                validationSchema={validationsSchema}
            >
                {({
                    handleSubmit,
                    isSubmitting
                }) => (
                    <Form className="about-form" onSubmit={handleSubmit} id="form">
                        <p className="about-form__title h2">Задать вопрос</p>
                        <div className="about-form__grid form">
                            <div className="form__field">
                                <Field
                                    type="text"
                                    name="name"
                                    placeholder="ваше имя" />
                                <ErrorMessage name="name" component="span" className="form__error" />
                            </div>
                            <div className="form__field">
                                <Field name="phone">
                                    {({ field }) => <MaskedInput
                                        {...field}
                                        mask={phoneNumberMask}
                                        placeholder="телефон"
                                        type="tel"
                                    />
                                    }
                                </Field>
                                <ErrorMessage name="phone" component="span" className="form__error" />
                            </div>
                            <div className="form__field">
                                <Field
                                    type="email"
                                    name="email"
                                    placeholder="E-mail" />
                                <ErrorMessage name="email" component="span" className="form__error" />
                            </div>
                            <div className="form__textarea">
                                <label htmlFor="about_text">Причина обращения</label>
                                <Field
                                    as="textarea"
                                    name="comment"
                                    id="about_text" />
                                <ErrorMessage name="comment" component="span" className="form__error" />
                            </div>
                            <div className="form__check">
                                <Field
                                    className="visually-hidden"
                                    name="check"
                                    type="checkbox"
                                    checked
                                    id="about_check" />
                                <label htmlFor="about_check"><span>Я согласен с <Link to="">политикой конфиденциальности и обработки персональных данных</Link></span></label>
                            </div>
                            <button type="submit" disabled={isSubmitting} className="form__btn btn gradient">отправить</button>
                        </div>
                    </Form>
                )}
            </Formik>
            {resultShow && <ModalPopup setShow={setResultShow} addedClass="form_result">
                <FormResult status={resultText.status} text={resultText.text} />
            </ModalPopup>}
        </>
    );
};
export default AccountForm;