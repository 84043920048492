import useParamInt from "app/hooks";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetNewsListQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Pagination from "shared/Pagination";
import HeadTags from "shared/HeadTags";
import 'swiper/css';
import { NewsItem } from "services/api/types/News";
import FormatDate from "lib/FormatDate";
import ym from 'react-yandex-metrika';

const NewsListItem = ({item}: {item: NewsItem}) => {
    const {dateDMY} = FormatDate(item.created);

    return (
        <li>
            <Link to={`/novosti/${item.slug}`} className="news-list-item">
                <img 
                    src={item.thumbnail} 
                    alt={item.title}
                    className="news-list-item__photo" />
                <span className="news-list-item__date">{dateDMY}</span>
                <span className="news-list-item__name">{item.title}</span>
                <span className="news-list-item__text">{item.lead}</span>
                <span className="news-list-item__link"><span className="btn gradient">читать</span></span>
            </Link>
        </li>
    );
};

const NewsPage = ({setPageClass}) => {
    const [limit, setLimit] = useState<number>(12);
    const [offset, setOffset] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const location = useNavigate();
    const ref = useRef(null);
    const { data, isLoading } = useGetNewsListQuery({limit, offset});

    useEffect(() => {
        setOffset(0);
        setPage(1);
        setLimit(12);
    }, [location]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [page]);

    useEffect(() => {
        setPageClass('news-page');
        ym('hit', `/novosti`);
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
         _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);

    const newsList = data
        ? data.results.length
            ? data.results.map(item => {
                return (
                    <NewsListItem key={item.id} item={item} />
                )
            })
            : 'Новостей пока нет'
        : 'Загрузка...';

    return (
        <section className="news container">
            <HeadTags
                title='Новости - Елена Полянская'
                metaDescription='Новости психолога Елены Полянской. Расписание предстоящих событий.'
            />
            <ul className="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
                <li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    <Link to="/" itemProp="item">Главная</Link>
                </li>
                <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem"><span itemProp="item">Новости</span></li>
            </ul>
            <h1 className="h2">Новости</h1>
            <LoadingBlock isLoading={isLoading}>
                <>
                    <ul className="news-list" itemScope itemType="http://schema.org/ItemList" ref={ref}>
                        {newsList}
                    </ul>
                    {data &&
                        <Pagination
                            pageSize={limit}
                            currentPage={page}
                            totalCount={data.count}
                            onPageChange={page => {
                                if (page === Infinity) {
                                    setLimit(data.count);
                                    setOffset(0);
                                    setPage(1);
                                } else {
                                    setOffset((page - 1) * limit);
                                    setPage(page);
                                }
                            }}
                        />
                    }
                </>
            </LoadingBlock>
        </section>
    )
}
export default NewsPage;