import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { NewsItem, NewsList, CourseItem, CoursesList, RetreatItem, RetreatsList, QuizList, QuizItem, FAQItem, EmployeeItem, VideosItem} from './types/News';
import { FormRequest, QuestionFormRequest, RegistrationFormRequest, LoginFormRequest, ConsultationBookingResponse, ConsultationBookingRequest, BuyCourseRequest, BuyWebinarRequest, BuyRetreatRequest } from './types/FormRequest';
import { User, UserData, Token, TokenRefresh } from './types/User';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/',
    prepareHeaders: (headers) => {
      headers.set('Accept', 'application/json');
      return headers;
    }
  }),
  tagTypes: ['User', 'Slots'],
  endpoints: (builder) => ({
    getNewsList: builder.query<NewsList, {limit?: number, offset?: number}>({
      query: () => `news/`,
    }),
    getNewsById: builder.query<NewsItem, string>({
      query: (slug) => `news/${slug}/`,
    }),
    getBlogList: builder.query<NewsList, {limit?: number, offset?: number}>({
      query: () => `blog/`,
    }),
    getBlogById: builder.query<NewsItem, string>({
      query: (slug) => `blog/${slug}/`,
    }),
    getFAQList: builder.query<FAQItem[], void>({
      query: () => `support/`,
    }),
    getCoursesList: builder.query<CoursesList, {limit?: number, offset?: number}>({
      query: () => `courses/`,
    }),
    getCourseById: builder.query<CourseItem, string>({
      query: (slug) => `courses/${slug}/`,
    }),
    getWebinarsList: builder.query<CoursesList, {limit?: number, offset?: number}>({
      query: () => `webinars/`,
    }),
    getWebinarById: builder.query<CourseItem, string>({
      query: (slug) => `webinars/${slug}/`,
    }),
    getRetreatsList: builder.query<RetreatsList, {limit?: number, offset?: number}>({
      query: () => `retreats/`,
    }),
    getRetreatById: builder.query<RetreatItem, string>({
      query: (slug) => `retreats/${slug}/`,
    }),
    getQuizList: builder.query<QuizList, {limit?: number, offset?: number}>({
      query: () => `quiz/`,
    }),
    getQuizById: builder.query<QuizItem, string>({
      query: (slug) => `quiz/${slug}/`,
    }),
    getTeamList: builder.query<EmployeeItem[], void>({
      query: () => `employees/`,
    }),
    getTeamItemById: builder.query<EmployeeItem, string>({
      query: (slug) => `employees/${slug}/`,
      providesTags: ['Slots']
    }),
    getVideosList: builder.query<VideosItem[], void>({
      query: () => `videos/`,
    }),
    createFormRequest: builder.mutation<FormRequest, { body: FormRequest}>({
      query: ({body}) => ({
        url: `consultation/anonymous/`,
        method: 'POST',
        body,
      })
    }),
    createUserFormRequest: builder.mutation<FormRequest, { body: FormRequest}>({
      query: ({body}) => ({
        url: `consultation/auth/`,
        method: 'POST',
        body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
    }),
    createSupportFormRequest: builder.mutation<FormRequest, { body: FormRequest}>({
      query: ({body}) => ({
        url: `users/auth/user-support/`,
        method: 'POST',
        body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
    }),
    createQuestionFormRequest: builder.mutation<QuestionFormRequest, { body: QuestionFormRequest}>({
      query: ({body}) => ({
        url: `support/support-request/`,
        method: 'POST',
        body,
      })
    }),
    createRegistrationFormRequest: builder.mutation<RegistrationFormRequest, { body: RegistrationFormRequest}>({
      query: ({body}) => ({
        url: `users/auth/register/`,
        method: 'POST',
        body,
      })
    }),
    createRegistrationConfirmFormRequest: builder.mutation<any, Token>({
      query: (body) => ({
        url: `users/auth/activation/`,
        method: 'POST',
        body
      })
    }),
    createLoginFormRequest: builder.mutation<TokenRefresh, { body: LoginFormRequest}>({
      query: ({body}) => ({
        url: `users/auth/login/`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['User']
    }),
    createUpdateUserDataFormRequest: builder.mutation<User, { body: UserData}>({
      query: ({body}) => ({
        url: `users/auth/me/partial_update/`,
        method: 'PATCH',
        body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      invalidatesTags: ['User']
    }),
    createPasswordResetFormRequest: builder.mutation<{email: string}, { body: {email: string}}>({
      query: ({body}) => ({
        url: `users/auth/password/reset/`,
        method: 'POST',
        body,
      })
    }),
    createPasswordResetConfirmFormRequest: builder.mutation<{uid: string, token:string, new_password: string}, { body: {uid: string, token:string, new_password: string}}>({
      query: ({body}) => ({
        url: `users/auth/password/reset/confirm/`,
        method: 'POST',
        body,
      })
    }),
    createCheckTokenValidRequest: builder.mutation<any, {token: string}>({
      query: (body) => ({
        url: `users/auth/token/verify/`,
        method: 'POST',
        body,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
    }),
    createRefreshTokenRequest: builder.mutation<{access: string}, {refresh: string}>({
      query: (body) => ({
        url: `users/auth/token/refresh/`,
        method: 'POST',
        body,
      })
    }),
    getUserData: builder.query<User, void>({
      query: () => ({
        url: `users/auth/me/`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      providesTags: ['User']
    }),
    bookConsultationSlot: builder.mutation<ConsultationBookingResponse, ConsultationBookingRequest>({
      query: (body) => ({
        url: `/schedule/book-timeslot/`,
        method: 'POST',
        body,
        headers: localStorage.getItem('token') 
          ? {Authorization: `Bearer ${localStorage.getItem('token')}`}
          : {}
      }),
      invalidatesTags: ['Slots']
    }),
    buyCourse: builder.mutation<{id: number, is_paid: boolean}, BuyCourseRequest>({
      query: (body) => ({
        url: `/users/user_course/create/`,
        method: 'POST',
        body,
        headers: localStorage.getItem('token') 
          ? {Authorization: `Bearer ${localStorage.getItem('token')}`}
          : {}
      })
    }),
    buyWebinar: builder.mutation<{id: number, is_paid: boolean}, BuyWebinarRequest>({
      query: (body) => ({
        url: `/users/user_webinar/create/`,
        method: 'POST',
        body,
        headers: localStorage.getItem('token') 
          ? {Authorization: `Bearer ${localStorage.getItem('token')}`}
          : {}
      })
    }),
    buyRetreat: builder.mutation<{id: number, is_paid: boolean}, BuyRetreatRequest>({
      query: (body) => ({
        url: `/users/user_retreat/create/`,
        method: 'POST',
        body,
        headers: localStorage.getItem('token') 
          ? {Authorization: `Bearer ${localStorage.getItem('token')}`}
          : {}
      })
    }),
    payAppointment: builder.mutation<{payment_url: string}, number>({
      query: (id) => ({
        url: `/payment/appointment/${id}/`,
        method: 'GET',
      }),
    }),
    payCourse: builder.mutation<{payment_url: string}, number>({
      query: (id) => ({
        url: `/payment/course/${id}/`,
        method: 'GET',
      }),
    }),
    payWebinar: builder.mutation<{payment_url: string}, number>({
      query: (id) => ({
        url: `/payment/webinar/${id}/`,
        method: 'GET',
      }),
    }),
    payRetreat: builder.mutation<{payment_url: string}, number>({
      query: (id) => ({
        url: `/payment/retreat/${id}/`,
        method: 'GET',
      }),
    }),
  }),
});

export const { 
  useGetNewsByIdQuery, useGetNewsListQuery,
  useGetBlogByIdQuery, useGetBlogListQuery,
  useGetCourseByIdQuery, useGetCoursesListQuery,
  useGetWebinarByIdQuery, useGetWebinarsListQuery,
  useGetRetreatByIdQuery, useGetRetreatsListQuery,
  useGetQuizByIdQuery, useGetQuizListQuery,
  useGetTeamItemByIdQuery, useGetTeamListQuery,
  useGetFAQListQuery, useGetVideosListQuery,
  useCreateFormRequestMutation, useCreateQuestionFormRequestMutation, useCreateSupportFormRequestMutation, useCreateUserFormRequestMutation,
  useCreateRegistrationFormRequestMutation, useCreateLoginFormRequestMutation, useCreateRegistrationConfirmFormRequestMutation, useCreateUpdateUserDataFormRequestMutation,
  useCreateCheckTokenValidRequestMutation, useCreateRefreshTokenRequestMutation,
  useCreatePasswordResetFormRequestMutation, useCreatePasswordResetConfirmFormRequestMutation,
  useGetUserDataQuery, useBookConsultationSlotMutation,
  usePayAppointmentMutation, usePayCourseMutation, usePayWebinarMutation, useBuyCourseMutation, useBuyWebinarMutation, useBuyRetreatMutation, usePayRetreatMutation
} = api;