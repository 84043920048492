import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import { useNavigate } from "react-router-dom";
import HeadTags from "shared/HeadTags";
import ConsultForm from "shared/ConsultForm";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import ep from "assets/images/ep_1.jpg";
import { useGetTeamListQuery, useGetTeamItemByIdQuery } from 'services/api/api';
import ym from 'react-yandex-metrika';

import "yet-another-react-lightbox/styles.css";
import 'swiper/css';
import LoadingBlock from "shared/LoadingBlock";

const PoluanskayaPage = ({ setPageClass }) => {
    const { data, isLoading } = useGetTeamListQuery();
    const poluanskayaId = data ? data.find(item => item.surname === 'Полянская')?.slug : undefined;
    const { data: person, isLoading: personLoading } = useGetTeamItemByIdQuery(poluanskayaId, {
        skip: poluanskayaId === undefined
    });
    const [galleryIndex, setGalleryIndex] = useState(-1);
    const handleGalleryClick = (index: number) => setGalleryIndex(index);
    const navigate = useNavigate();

    useEffect(() => {
        setPageClass('about-page');
        ym('hit', `/elena-polyanskaya`);
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="about-bg">
            <HeadTags
                title='Елена Полянская'
                metaDescription='Елена Полянская' />
            <section className="container">
                <ul className="breadcrumbs">
                    <li><Link to="/">главная</Link></li>
                    <li><Link to="/komanda">команда</Link></li>
                    <li><span>елена полянская</span></li>
                </ul>
                <div className="about">
                    <img src={ep} className="about__photo" alt="Елена Полянская" />
                    <div className="about__block">
                        <h1 className="about__title">Елена <span>Полянская</span></h1>
                        <p className="about__subtitle">Кто я?</p>
                        <p>Я, Елена Полянская, психолог, работающий с человеческими чувствами, проблемами, болезнями.</p>
                        <p>Работаю через контакт с чувствами, телом, семьей, родом и большими процессами, затрагивающими человека. Работаю на исцеление, которое гармонизирует и тело, и душу.</p>
                    </div>
                    <div className="about__text">
                        <p className="about__subtitle">С чем ко мне можно обращаться?</p>
                        <p>- Если хочется понять по каким законам устроены отношения в семье, роду, в паре.</p>
                        <p>- Если хочется не просто понять общее устройство человеческих взаимоотношений, но увидеть, что такое происходит с вами и именно в вашей семье, роду, с вашими отношениями – семейные сценарии, родовые проблемы, переходящие из поколения в поколение.</p>
                    </div>
                    <div className="about__text">
                        <p className="about__subtitle">Мой рабочий инструмент: </p>
                        <ul className="check-list">
                            <li>знания (ВУЗ, много допобразования, дипломы можно посмотреть тут),</li>
                            <li>хороший контакт с собой (много лет работы со своими психологами, и мои родители, которые этому не мешали),</li>
                            <li>опыт (работа в соматических и психиатрическом стационарах, работа с детьми с расстройствами психического развития и их семьями, более 13 лет частной психологической практики)</li>
                            <li>сверхчувствительность, от которой я раньше мучалась, а теперь наслаждаюсь :)</li>
                        </ul>
                    </div>
                </div>
                <ConsultForm />
            </section>
            <section className="container about-price">
                <h2>Мои консультации стоят дорого потому что: </h2>
                <ol className="about-price__list">
                    <li className="about-price__list-item"><p>я досконально знаю свою работу</p></li>
                    <li className="about-price__list-item"><p>я работаю по призванию, это дело моей жизни и мой талант, которые служат вам, вашей жизни и жизни в широком смысле</p></li>
                    <li className="about-price__list-item"><p>у меня высокая результативность работы в решении ваших проблем, близкая к 90%</p></li>
                    <li className="about-price__list-item"><p>я интегрирую в своей работе редкие навыки, которыми обладают единицы, так как обучение и практика требуют годы обучения и работы над собой. И особых способностей.</p></li>
                    <li className="about-price__list-item"><p>в моей практике за многие годы из тысяч обратившихся не получили результата единицы. Это значит мы не подошли в работе, это бывает, это нормально. Значит мы просто не будем работать. Закончить рабочие терапевтические отношения можно в любой момент.</p></li>
                </ol>
            </section>
            <LoadingBlock isLoading={personLoading}>
                {(person && person.certificates.length > 0) && <section className="about-sert container">
                    <h3>сертификаты</h3>
                    <Lightbox
                        slides={person.certificates.map(el => {
                            return (
                                { src: el.image }
                            )
                        })}
                        open={galleryIndex >= 0}
                        index={galleryIndex}
                        close={() => setGalleryIndex(-1)}
                    />
                    <div className="main-video-list" id="about_sert_list">
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={0}
                            slidesPerView={5}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            }}
                            breakpoints={{
                                481: {
                                    slidesPerView: 3
                                },
                                769: {
                                    slidesPerView: 4
                                },
                                981: {
                                    slidesPerView: 5
                                },
                            }}
                            loop={false}
                            lazy>
                            {person.certificates.map((image, idx) => (
                                <SwiperSlide key={image.id}>
                                    <div
                                        className="slick-slide"
                                        onClick={() => handleGalleryClick(idx)}>
                                        <div className="about-sert__item">
                                            <img src={image.image}
                                                alt={image.name} />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                    </div>
                </section>}
            </LoadingBlock>
        </div>
    )
}
export default PoluanskayaPage;