import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DaySchedule } from '../services/api/types/News';
import { useBookConsultationSlotMutation, useGetUserDataQuery, usePayAppointmentMutation } from 'services/api/api';
import ModalPopup from "shared/ModalPopup";
import FormResult from "shared/FormResult";
import ConsultForm from './ConsultForm';

const formatDate = (dateStr: string) => {
    // Преобразование строки в объект Date
    let dateObj = new Date(dateStr);
    // Форматирование даты
    let options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long' };
    let formattedDate = new Intl.DateTimeFormat('ru-RU', options).format(dateObj);
    return formattedDate;
};

type ScheduleProps = {
    data: DaySchedule[],
    selected: number,
    setSelected: (id: number) => void,
    modal?: boolean,
    setShow?: (show: boolean) => void
};

const Schedule = ({ data, selected, setSelected, modal, setShow }: ScheduleProps) => {
    const navigate = useNavigate();
    const [visibleCount, setVisibleCount] = useState(3);
    const [bookRequest] = useBookConsultationSlotMutation();
    const [payRequest] = usePayAppointmentMutation();
    const { data: userData } = useGetUserDataQuery();
    const [resultShow, setResultShow] = useState(false);
    const [resultText, setResultText] = useState({
        status: '',
        text: ''
    });
    const [formShow, setFormShow] = useState(false);
    const notEmptyDays = useMemo(() => data ? data.filter(day => day.slots.length > 0) : [], [data]);
    const userId = useMemo(() => userData ? userData.id : undefined, [userData]);

    const handleShowMore = () => {
        setVisibleCount(prevCount => prevCount + 1);
    };
    const handleCloseAllPopup = (show: boolean) => {
        setResultShow(show);
        if (formShow) {
            setFormShow(show);
        }
        if (modal && setShow && resultText.status === 'Спасибо!') {
            setShow(show);
        }
    };
    const handleBookFormError = () => {
        setResultShow(true);
        setResultText({
            status: 'Ошибка!',
            text: `Попробуйте позже`
        });
    };
    const handleBookFormSuccess = async (id) => {
        setSelected(undefined);
        setResultShow(true);
        setResultText({
            status: 'Спасибо!',
            text: `Идет перенаправление на страницу оплаты...`
        });
        const res = await payRequest(id);
        if ("error" in res) {
            handleBookFormError();
        } if ("data" in res && res.data) {
            window.location.href = res.data.payment_url;
        }
    };
    const handleBook = async () => {
        if (userId) {
            const res = await bookRequest({
                timeslot: selected
            });
            if ("error" in res) {
                handleBookFormError();
            } if ("data" in res && res.data) {
                handleBookFormSuccess(res.data.id);
            }
        } else {
            setFormShow(true);
        }
    };

    return (
        <>
            <div className="schedule">
                <p className="h2 schedule__title">РАСПИСАНИЕ</p>
                <p className="schedule__text">Выберите время сессии с психологом<br /> <span>Europe/Moscow</span></p>
                {notEmptyDays.length > 0
                    ? <>
                        {notEmptyDays.slice(0, visibleCount).map(day => <div className="schedule-row" key={day.date}>
                            <p className="schedule-row__date">{formatDate(day.date)}, {day.weekday.toLocaleLowerCase()}</p>
                            {day.slots.map(slot => <button className={selected === slot.id ? 'active' : ''} key={slot.id} onClick={() => setSelected(slot.id)}>{slot.start_time.slice(0, -3)}</button>)}
                        </div>)}
                        {visibleCount < data.length && <div className="schedule__more">
                            <button type="button" onClick={handleShowMore}>Ещё 1 день</button>
                        </div>}
                        <button
                            type="button"
                            className="schedule__submit btn gradient"
                            onClick={handleBook}
                            disabled={!selected}>
                            {selected ? 'Записаться' : 'Выбрать время сессии'}
                        </button></>
                    : <p>Свободных дат пока нет</p>
                }
            </div>
            {formShow && <ModalPopup setShow={handleCloseAllPopup} addedClass='modal_form'>
                <ConsultForm
                    type="book"
                    booking={true}
                    bookingSlotId={selected}
                    onBookError={handleBookFormError}
                    onBookSuccess={handleBookFormSuccess}
                    btnText='Записаться' />
            </ModalPopup>}
            {resultShow && <ModalPopup setShow={handleCloseAllPopup} addedClass="form_result">
                <FormResult status={resultText.status} text={resultText.text} />
            </ModalPopup>}
        </>

    );
};
export default Schedule;