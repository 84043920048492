import { useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingBlock from "shared/LoadingBlock";
import HeadTags from "shared/HeadTags";
import { useNavigate } from "react-router-dom";
import ConsultForm from "shared/ConsultForm";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { useGetRetreatByIdQuery, useGetUserDataQuery, usePayRetreatMutation, useBuyRetreatMutation } from "services/api/api";
import parse from 'html-react-parser';
import ym from 'react-yandex-metrika';
import ModalPopup from "shared/ModalPopup";
import FormResult from "shared/FormResult";
import FormatDate from "lib/FormatDate";
import FormatPrice from "lib/FormatPrice";
import 'swiper/css';

const RetreatPage = ({ setPageClass }) => {
    const { slug } = useParams();
    const { data, isLoading } = useGetRetreatByIdQuery(slug);
    const navigate = useNavigate();
    const { data: userData } = useGetUserDataQuery();
    const [payRequest] = usePayRetreatMutation();
    const [buyRequest] = useBuyRetreatMutation();
    const userId = useMemo(() => userData ? userData.id : undefined, [userData]);
    const retreatId = useMemo(() => data ? data.id : undefined, [data]);
    const [resultShow, setResultShow] = useState(false);
    const [payFormShow, setPayFormShow] = useState(false);
    const [resultText, setResultText] = useState({
        status: '',
        text: ''
    });

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        setPageClass('news-item-page');
        ym('hit', `/uslugi/retriti/${slug}`);
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    const handleFormError = () => {
        setResultShow(true);
        setResultText({
            status: 'Ошибка!',
            text: `Попробуйте позже`
        });
    };
    const handleCloseAllPopup = (show: boolean) => {
        setResultShow(show);
        if (payFormShow) {
            setPayFormShow(show);
        }
    };
    const handleFormSuccess = async (id) => {
        setResultShow(true);
        setResultText({
            status: 'Спасибо!',
            text: `Идет перенаправление на страницу оплаты...`
        });
        const res = await payRequest(id);
        if ("error" in res) {
            handleFormError();
        } if ("data" in res && res.data) {
            window.location.href = res.data.payment_url;
        }
    };
    const handleBuy = async () => {
        if (!retreatId) return;
        if (userId) {
            const res = await buyRequest({
                retreat_id: retreatId
            });
            if ("error" in res) {
                handleFormError();
            } if ("data" in res && res.data) {
                handleFormSuccess(res.data.id);
            }
        } else {
            setPayFormShow(true);
        }
    };

    return (
        <>
            <HeadTags
                title={data ? data.seo_title : 'Ретриты'}
                metaDescription={data ? data.seo_description : 'Ретриты'} />
            <LoadingBlock isLoading={isLoading}>
                <>
                    {data
                        ? <section className="news-item container">
                            <ul className="breadcrumbs">
                                <li><Link to="/">главная</Link></li>
                                <li><Link to="/uslugi">Услуги</Link></li>
                                <li><Link to="/uslugi/retriti">Ретриты</Link></li>
                                <li><span>{data.title}</span></li>
                            </ul>
                            <p className="retreat-item__date">
                                <span>Дата: <strong>{FormatDate(data.date).dateDM}</strong></span>
                                <span>Продолжительность - {data.duration}</span>
                            </p>
                            <h1 className="h2">{data.title}</h1>
                            {data.video_link
                                ? <div className="news-item__video">
                                    <iframe
                                        width="100%"
                                        title={data.title}
                                        src={`https://www.youtube.com/embed/${new URL(data.video_link).searchParams.get("v")}`}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen />
                                </div>
                                : <img src={data.image} alt={data.title} className="news-item__photo" />
                            }
                            <div className="news-item__text">
                                {parse(data.text_before_slider)}
                                {data.gallery.length > 0 && <div className="retreat-item__slider">
                                    <Swiper
                                        modules={[Navigation, Pagination]}
                                        spaceBetween={30}
                                        slidesPerView={3}
                                        centeredSlides
                                        navigation
                                        pagination={{
                                            el: '.swiper-pagination',
                                        }}
                                        breakpoints={{
                                            '768': {
                                                spaceBetween: 50,
                                            },
                                            '1199': {
                                                spaceBetween: 95,
                                            },
                                        }}
                                        loop
                                        lazy>
                                        {data.gallery.map((slide, idx) => (
                                            <SwiperSlide key={`slide_${idx}`}>
                                                <img src={slide.image} alt={slide.name} />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div className="swiper-pagination"></div>
                                </div>}
                                {parse(data.text_after_slider)}

                                {!!data.price &&  <div className="webinar-item__btns">
                                    <p className="news-item__orange">Стоимость <span>{FormatPrice(data.price)}₽</span></p>
                                    <button className="btn gradient webinar-item__link" onClick={handleBuy}>купить курс</button>
                                </div>}

                                <button onClick={goBack} className="news-item__back btn">вернуться в новости</button>
                            </div>
                            <ConsultForm />
                        </section>
                        : <p>Ошибка получения данных</p>
                    }
                </>
            </LoadingBlock >
            {payFormShow && <ModalPopup setShow={handleCloseAllPopup} addedClass='modal_form'>
                <ConsultForm
                    booking={true}
                    type="buy_retreat"
                    bookingSlotId={retreatId}
                    onBookError={handleFormError}
                    onBookSuccess={handleFormSuccess}
                    btnText='Купить ретрит' />
            </ModalPopup>}
            {resultShow && <ModalPopup setShow={handleCloseAllPopup} addedClass="form_result">
                <FormResult status={resultText.status} text={resultText.text} />
            </ModalPopup>}
        </>
    )
}
export default RetreatPage;