import { Link, useLocation } from "react-router-dom";
import { useCreateFormRequestMutation, useCreateUserFormRequestMutation, useGetUserDataQuery, useBookConsultationSlotMutation, useBuyCourseMutation, useBuyWebinarMutation, useBuyRetreatMutation } from "services/api/api";
import { FormRequest } from "services/api/types/FormRequest";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import MaskedInput from "react-text-mask";
import { useMemo, useState } from 'react';
import ModalPopup from "shared/ModalPopup";
import FormResult from "shared/FormResult";

type ConsultFormProps = {
    btnText?: string,
    booking?: boolean,
    type?: 'book'|'buy_course'|'buy_webinar'|'buy_retreat',
    bookingSlotId?: number,
    onBookSuccess?: (id: number) => void,
    onBookError?: () => void,
}
const setTitle = (type: 'book'|'buy_course'|'buy_webinar'|'buy_retreat') => {
    if (type === 'book') return 'Записаться';
    if (type === 'buy_course') return 'Купить курс';
    if (type === 'buy_webinar') return 'Купить вебинар';
    if (type === 'buy_retreat') return 'Купить ретрит';
}

const ConsultForm = ({ btnText = 'Отправить', booking, bookingSlotId, onBookSuccess, onBookError, type }: ConsultFormProps) => {
    const { data } = useGetUserDataQuery();
    const [createFormRequest] = useCreateFormRequestMutation();
    const [createUserFormRequest] = useCreateUserFormRequestMutation();
    const [bookRequest] = useBookConsultationSlotMutation();
    const [buyCourseRequest] = useBuyCourseMutation();
    const [buyWebinarRequest] = useBuyWebinarMutation();
    const [buyRetreatRequest] = useBuyRetreatMutation();
    const phoneNumberMask = [
        "+",
        "7",
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/
    ];
    const validationsSchema = yup.object().shape({
        name: yup.string()
            .min(3, 'Слишком короткое имя')
            .matches(
                /[а-я,А-Я]/,
                'Имя должно содержать только русские буквы'
            )
            .matches(/^([^0-9]*)$/gm, "'Имя должно содержать только буквы")
            .required('Обязательно к заполнению'),
        phone: yup.string()
            .required('Обязательно к заполнению')
            .transform(value => value.replace(/[^\d]/g, ''))
            .min(11, "Введите корректный номер"),
        comment: yup.string()
            .test(
                'nourl',
                'Текст сообщения введен некорректно',
                (value) => {
                    return !value.match(/(www\.)|(https?:)|(\.ru)|(\.com)|(\.рф)/)
                }
            )
            .required('Обязательно к заполнению'),
        email: yup.string()
            .email('Введите верный email')
            .required('Обязательно к заполнению'),
        check: yup.bool()
            .oneOf([true], 'Дайте согласие на обработку персональных данных'),
    });
    const bookingValidationsSchema = yup.object().shape({
        name: yup.string()
            .min(3, 'Слишком короткое имя')
            .matches(
                /[а-я,А-Я]/,
                'Имя должно содержать только русские буквы'
            )
            .matches(/^([^0-9]*)$/gm, "'Имя должно содержать только буквы"),
        phone: yup.string()
            .transform(value => value.replace(/[^\d]/g, ''))
            .min(11, "Введите корректный номер"),
        comment: yup.string()
            .test(
                'nourl',
                'Текст сообщения введен некорректно',
                (value) => {
                    if (!value) return true;
                    return !value.match(/(www\.)|(https?:)|(\.ru)|(\.com)|(\.рф)/)
                }
            ),
        email: yup.string()
            .email('Введите верный email')
            .required('Обязательно к заполнению'),
        check: yup.bool()
            .oneOf([true], 'Дайте согласие на обработку персональных данных'),
    });
    const [resultShow, setResultShow] = useState(false);
    const [resultText, setResultText] = useState({
        status: '',
        text: ''
    });
    const userId = useMemo(() => data ? data.id : undefined, [data]);

    return (
        <>
            <Formik
                initialValues={{
                    name: '',
                    phone: '',
                    email: '',
                    comment: '',
                    check: true
                }}
                validateOnBlur
                onSubmit={async ({ name, phone, comment, email }, { resetForm }) => {
                    const body: FormRequest = {
                        name,
                        phone,
                        comment,
                        email,
                        form_source_url: `${window.location}`
                    };
                    let res;
                    if (booking && bookingSlotId) {
                        if (type) {
                            if (type === 'book') {
                                res = await bookRequest(Object.assign(
                                    {
                                        email,
                                        timeslot: bookingSlotId
                                    },
                                    name ? { first_name: name } : null,
                                    phone ? { phone } : null,
                                    comment ? { comment } : null
                                ));
                            } else if (type === 'buy_course') {
                                res = await buyCourseRequest(Object.assign(
                                    {
                                        email,
                                        course_id: bookingSlotId
                                    },
                                    name ? { first_name: name } : null,
                                    phone ? { phone } : null,
                                    comment ? { comment } : null
                                ));
                            } else if (type === 'buy_retreat') {
                                res = await buyRetreatRequest(Object.assign(
                                    {
                                        email,
                                        retreat_id: bookingSlotId
                                    },
                                    name ? { first_name: name } : null,
                                    phone ? { phone } : null,
                                    comment ? { comment } : null
                                ));
                            } else {
                                res = await buyWebinarRequest(Object.assign(
                                    {
                                        email,
                                        webinar_id: bookingSlotId
                                    },
                                    name ? { first_name: name } : null,
                                    phone ? { phone } : null,
                                    comment ? { comment } : null
                                ));
                            }
                            if (res['error']) {
                            onBookError();
                            } if ("data" in res && res.data) {
                                onBookSuccess(res.data.id);
                            }
                        }
                    } else {
                        if (userId) {
                            res = await createUserFormRequest({ body: { ...body, user: userId } });
                        } else {
                            res = await createFormRequest({ body });
                        }
                        if (res['error']) {
                            setResultShow(true);
                            setResultText({
                                status: 'Ошибка!',
                                text: `Попробуйте позже`
                            });
                        } else {
                            resetForm();
                            setResultShow(true);
                            setResultText({
                                status: 'Спасибо!',
                                text: `Ваша заявка отправлена`
                            });
                        }
                    }
                }}
                validationSchema={booking ? bookingValidationsSchema : validationsSchema}
            >
                {({
                    handleSubmit,
                    isSubmitting
                }) => (
                    <Form className="about-form" onSubmit={handleSubmit} id="form">
                        <p className="about-form__title h2">{type ? setTitle(type) : 'Получить консультацию'}</p>
                        <div className="about-form__grid form">
                            <div className="form__field">
                                <Field
                                    type="text"
                                    name="name"
                                    placeholder="ваше имя" />
                                <ErrorMessage name="name" component="span" className="form__error" />
                            </div>
                            <div className="form__field">
                                <Field name="phone">
                                    {({ field }) => <MaskedInput
                                        {...field}
                                        mask={phoneNumberMask}
                                        placeholder="телефон"
                                        type="tel"
                                    />
                                    }
                                </Field>
                                <ErrorMessage name="phone" component="span" className="form__error" />
                            </div>
                            <div className="form__field">
                                <Field
                                    type="email"
                                    name="email"
                                    placeholder={`E-mail${booking && '*'}`} />
                                <ErrorMessage name="email" component="span" className="form__error" />
                            </div>
                            <div className="form__textarea">
                                <label htmlFor="about_text">комментарий</label>
                                <Field
                                    as="textarea"
                                    name="comment"
                                    id="about_text" />
                                <ErrorMessage name="comment" component="span" className="form__error" />
                            </div>
                            <div className="form__check">
                                <Field
                                    className="visually-hidden"
                                    name="check"
                                    type="checkbox"
                                    checked
                                    id="about_check" />
                                <label htmlFor="about_check"><span>Я согласен с <a href={process.env.PUBLIC_URL + '/politic.pdf?v=1'} target="_blank" rel="noreferrer">политикой конфиденциальности</a> и <a href={process.env.PUBLIC_URL + '/personal.pdf?v=1'} target="_blank" rel="noreferrer">обработки персональных данных</a></span></label>
                            </div>
                            <button type="submit" disabled={isSubmitting} className="form__btn btn gradient">{btnText}</button>
                        </div>
                    </Form>
                )}
            </Formik>
            {resultShow && <ModalPopup setShow={setResultShow} addedClass="form_result">
                <FormResult status={resultText.status} text={resultText.text} />
            </ModalPopup>}
        </>
    );
};
export default ConsultForm;
