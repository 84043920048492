import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeadTags from "shared/HeadTags";
import { useCreatePasswordResetConfirmFormRequestMutation} from 'services/api/api';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

const ConfirmPasswordPage = ({setPageClass}: {setPageClass?: (pageClass: string) => void}) => {
	const {uid, token} = useParams();
	const [createFormRequest] = useCreatePasswordResetConfirmFormRequestMutation();
	const validationsSchema = yup.object().shape({
        new_password: yup.string()
			.required('Обязательно к заполнению')
			.min(8, 'Минимальная длина пароля 8 символов')
			.matches(/^[^а-яА-ЯёЁ]*$/, "Пароль не должен содержать русские буквы")
			.matches(/[0-9]/, 'Пароль должен содержать хотя бы одну цифру')
			.matches(/[a-z,A-Z]/, 'Пароль должен содержать хотя бы одну букву')
    });
    const [resultShow, setResultShow] = useState(false);
    const [resultText, setResultText] = useState({
        status: '',
        text: ''
    });
	useEffect(() => {
		setPageClass('no-bg');
    }, []);

	return (
		<div className="container account">
			<HeadTags
				title='Личный кабинет'
				metaDescription='Восстановление данных'
			/>
			<p className="recovery__title">Восстановление пароля</p>
			<Formik
				initialValues={{
					new_password: ''
				}}
				validateOnBlur
				onSubmit={async ({ new_password }, {resetForm}) => {
					const body: {uid: string, token:string, new_password: string} = {
						uid, 
						token,
						new_password
					};
					let res = await createFormRequest({ body });
					if (res['error']) {
						setResultShow(true);
						setResultText({
							status: '',
							text: res['error'].data ?  Object.values(res['error'].data).map((el: string[]) => el.join('\n')).join('\n') : 'Попробуйте позже'
						});
					} else {
						resetForm();
						setResultShow(true);
						setResultText({
							status: 'success',
							text: 'Пароль успешно изменен.'
						});
					}
				}}
				validationSchema={validationsSchema}
			>
				{({
					handleSubmit,
					isSubmitting
				}) => (
					<Form className="form profile-form profile-form--recovery" onSubmit={handleSubmit}>
						<div className="form__field">
							<Field
                                type="password"
                                name="new_password"
                                placeholder="НОВЫЙ ПАРОЛЬ*" /> 
                            <ErrorMessage name="new_password" component="span" className="form__error" />
						</div>
						<button type="submit" disabled={isSubmitting} className="form__btn btn gradient">оТПРАВИТЬ</button>
					</Form>
				)}
			</Formik>
			{resultShow && <div className={`profile-form__result ${resultText.status === 'success' ? 'success' : ''}`}>
				{resultText.text}<br/>
				{resultText.status === 'success' && <Link to='/auth'>Войдите в личный кабинет</Link>}
			</div>}
		</div>
	)
}
export default ConfirmPasswordPage;