import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import HeadTags from "shared/HeadTags";
import parse from 'html-react-parser';
import ym from 'react-yandex-metrika';
import { useGetUserDataQuery } from "services/api/api";
import Spinner from 'shared/Spinner';
import { UserWebinar, UserCourse } from 'services/api/types/User';

const OrderPage = ({ setPageClass }: { setPageClass?: (pageClass: string) => void }) => {
	const { type, slug } = useParams();
	const { data, error, isLoading } = useGetUserDataQuery();
	const contentType = type === 'courses' ? 'course' : 'webinar';
	const order: UserWebinar|UserCourse = data ? data[type].filter(el => el.hasOwnProperty(contentType)).find(el => el[contentType].slug === slug) : null;
	const orderContent =  order ? order[contentType] : null;
	const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	};
	useEffect(() => {
		setPageClass('account-page');
		ym('hit', `/lk/my-orders/${type}/${slug}`);
	}, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}, [navigate]);

	return (
		<div className="order-item">
			{isLoading
				? <Spinner />
				: error
					? <p>Ошибка получения данных</p>
					: <>
						<HeadTags
							title={orderContent?.seo_title || orderContent.title}
							metaDescription={orderContent?.seo_description || orderContent.title}
						/>
						<h2 className="order-item__title">{orderContent.title}</h2>
						{orderContent?.purchased_course_link && <div className="order-item__video">
							{parse(orderContent?.purchased_course_link)}
						</div>}
						{orderContent?.purchased_webinar_link && <div className="order-item__video">
							{parse(orderContent?.purchased_webinar_link)}
						</div>}
						<div className="order-item__text">
							{parse(orderContent.text)}
						</div>
					</>
			}
			<button type="button" className="order-item__back btn gradient" onClick={goBack}>Вернуться</button>
		</div>
	);
};
export default OrderPage;