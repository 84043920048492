import HeadTags from "shared/HeadTags";
import { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { useGetCoursesListQuery } from 'services/api/api';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useNavigate } from "react-router-dom";
import group_1 from 'assets/images/ed_group_1.jpg';
import LoadingBlock from "shared/LoadingBlock";
import FormatPrice from "lib/FormatPrice";
import FormatDate from "lib/FormatDate";
import Pagination from "shared/Pagination";
import ConsultForm from "shared/ConsultForm";
import ym from 'react-yandex-metrika';

const CoursesListItem = ({ item }) => {
    const { dateDMY } = FormatDate(item.date);
    return (
        <li>
            <Link to={`/kursi/${item.slug}`} className="webinars-list-item">
                <img
                    src={item.thumbnail}
                    alt={item.title}
                    className="webinars-list-item__photo" />
                <span className="webinars-list-item__text">
                    <span className="webinars-list-item__date">{dateDMY}</span>
                    <span className="webinars-list-item__name">{item.title}</span>
                    {!!item.price && <span className="webinars-list-item__link">
                        <span className="webinars-list-item__price">Купить<br /> за <b>{FormatPrice(item.price)} ₽</b></span>
                        <span className="btn gradient">подробнее</span>
                    </span>}
                </span>
            </Link>
        </li>
    );
};
const EducationGroupPage = ({ setPageClass }) => {
    const [limit, setLimit] = useState<number>(12);
    const [offset, setOffset] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const location = useNavigate();
    const ref = useRef(null);
    const { data, isLoading } = useGetCoursesListQuery({ limit, offset });

    useEffect(() => {
        setPageClass('education-group-page');
        ym('hit', '/uslugi/obuchauschie-gruppi');
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);
    useEffect(() => {
        setOffset(0);
        setPage(1);
        setLimit(12);
    }, [location]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [page, location]);

    const list = data
        ? data.results.length
            ? data.results.map(item => {
                return (
                    <CoursesListItem key={item.id} item={item} />
                )
            })
            : 'Здесь пока ничего нет'
        : 'Загрузка...';

    return (
        <section className="personal-consultation education-group container">
            <HeadTags
                title='Обучающие группы для психологов и не психологов - Елена Полянская'
                metaDescription='Обучающие группы'
            />
            <ul className="breadcrumbs">
                <li><Link to="/">главная</Link></li>
                <li><Link to="/uslugi">УСЛУГИ</Link></li>
                <li><span>Обучающие группы</span></li>
            </ul>
            <h1 className="h2">Обучающие группы</h1>
            <div className="team-item-about">
                <img src={group_1} className="team-item-about__photo" alt="Елена Полянская" />
                <div className="team-item-about__block">
                    <p>Я провожу обучающие группы. Там я рассказываю теорию и даю практику:</p>
                    <ul className="check-list">
                        <li>с чем имеем дело в терапии</li>
                        <li>предмет и понятия терапии</li>
                        <li>как устроеы психические процессы, по каким законам строятся отношения терапевта с клиентом, стадии развития психических процессов, этапы отношений разных видов</li>
                        <li>виды и способы интервенций</li>
                        <li>общие и частные случаи в практике, сложности и подводные камни</li>
                    </ul>
                    <p>Я не учу конкретному одному методу работы. Я учу быть терапевтом, работать на благо  клиента и в пользу себе.<br /> Терапевт - это тот, кто не просто знает психологию, но может поправлять и направлять в исцеляющее движение. В промежутке между обучающими сессиями участники курса объединяются для отработки методов и способов работы.</p>
                </div>
            </div>
            <h2>Расписание курсов </h2>
            <LoadingBlock isLoading={isLoading}>
                <>
                    <ul className="webinars-list" itemScope itemType="http://schema.org/ItemList" ref={ref}>
                        {list}
                    </ul>
                    {data &&
                        <Pagination
                            pageSize={limit}
                            currentPage={page}
                            totalCount={data.count}
                            onPageChange={page => {
                                if (page === Infinity) {
                                    setLimit(data.count);
                                    setOffset(0);
                                    setPage(1);
                                } else {
                                    setOffset((page - 1) * limit);
                                    setPage(page);
                                }
                            }}
                        />
                    }
                </>
            </LoadingBlock>
            <ConsultForm/>
        </section>
    )
};
export default EducationGroupPage;
