import ModalPopup from "./ModalPopup";
import ConsultForm from "./ConsultForm";

const PopupForm = ({setShow}) => {
    return (
        <ModalPopup setShow={setShow} addedClass='modal_form'>
           <ConsultForm btnText='заказать'/>
        </ModalPopup>
    );
};
export default PopupForm;