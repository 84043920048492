import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams} from "react-router-dom";
import LoadingBlock from "shared/LoadingBlock";
import HeadTags from "shared/HeadTags";
import ConsultForm from "shared/ConsultForm";
import BuyForm from "shared/BuyForm";
import ModalPopup from "shared/ModalPopup";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import { useGetCourseByIdQuery, usePayCourseMutation, useGetUserDataQuery, useBuyCourseMutation } from "services/api/api";
import FormatDate from "lib/FormatDate";
import FormatPrice from "lib/FormatPrice";
import PopupForm from "shared/PopupForm";
import FormResult from "shared/FormResult";
import ym from 'react-yandex-metrika';
import { Feedback } from "services/api/types/News";
import { FAQItem } from "services/api/types/News";
import { useGetFAQListQuery } from "services/api/api";
import 'swiper/css';

import "style/content/webinar_content.css";

const Review = ({ review, showReview }: {review: Feedback, showReview: (review: Feedback) => void}) => {
    return (
        <>
            <div
                className="course-review">
                <p className="course-review__name">{review.name}</p>
                <div className="course-review__text">
                    <div className="course-review__ellipsis">
                        {parse(review.text)}
                    </div>
                </div>
                <button
                    type="button"
                    onClick={() => showReview(review)}
                    className="course-review__btn">Читать полностью</button>
            </div>
        </>
    )
};
const ReviewPopup = ({ review }) => {
    return (
        <div className="popup course-popup">
            <div className="popup__content">
                <div className="course-review">
                    <p className="course-review__name">{review.name}</p>
                    <div className="course-review__text">
                        {parse(review.text)}
                    </div>
                </div>
            </div>
        </div>
    );
};

const CoursePage = ({ setPageClass }) => {
    const {slug} = useParams();
    const { data, isLoading } = useGetCourseByIdQuery(slug);
    const [reviewShow, setReviewShow] = useState(false);
    const [formShow, setFormShow] = useState(false);
    const [currentReview, setCurrentReview] = useState(null);
    const location = useNavigate();
    const { data: userData } = useGetUserDataQuery();
    const [payRequest] = usePayCourseMutation();
    const [buyCourseRequest] = useBuyCourseMutation();
    const userId = useMemo(() => userData ? userData.id : undefined, [userData]);
    const courseId = useMemo(() => data ? data.id : undefined, [data]);
    const [payFormShow, setPayFormShow] = useState(false);
    const [resultShow, setResultShow] = useState(false);
    const [resultText, setResultText] = useState({
        status: '',
        text: ''
    });
    const [courseData, setCourseData] = useState(null);
    const { currentData } = useGetFAQListQuery();


    useEffect(() => {
        setPageClass('news-item-page');
        ym('hit', `/kursi/${slug}`);
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [location]);
    useEffect(()=>{
        if(data) setCourseData(convertDate(data));
    },[data]);

    const showReview = (review) => {
        setReviewShow(true);
        setCurrentReview(review);
    };
    const handleFormError = () => {
        setResultShow(true);
        setResultText({
            status: 'Ошибка!',
            text: `Попробуйте позже`
        });
    };
    const handleCloseAllPopup = (show: boolean) => {
        setResultShow(show);
        if (payFormShow) {
            setPayFormShow(show);
        }
    };
    const handleFormSuccess = async (id) => {
        setResultShow(true);
        setResultText({
            status: 'Спасибо!',
            text: `Идет перенаправление на страницу оплаты...`
        });
        const res = await payRequest(id);
        if ("error" in res) {
            handleFormError();
        } if ("data" in res && res.data) {
            window.location.href = res.data.payment_url;
        }
    };
    const handleBuy = async () => {
        if (!courseId) return;
        if (userId) {
            const res = await buyCourseRequest({
                course_id: courseId
            });
            if ("error" in res) {
                handleFormError();
            } if ("data" in res && res.data) {
                handleFormSuccess(res.data.id);
            }
        } else {
            setPayFormShow(true);
        }
    };

    const convertDate = (data) => {
        if(data.date?.length > 0) {
            return FormatDate(data.date).dateDM;
        } else if (data.no_date_text?.length > 0) {
            return data.no_date_text;
        } else {
            return `${FormatDate(data.optional_date_start).dateDM} - ${FormatDate(data.optional_date_end).dateDM}`;
        }
    }

    return (
        <>
            <LoadingBlock isLoading={isLoading}>
                <>
                    <HeadTags
                        title={data ? data.seo_title : 'курсы'}
                        metaDescription={data ? data.seo_description : 'курсы'} />
                    {data
                        ? <>
                            <section className="course-item container">
                                <ul className="breadcrumbs">
                                    <li><Link to="/">главная</Link></li>
                                    <li><Link to="/kursi">курсы</Link></li>
                                    <li><span>{data.title}</span></li>
                                </ul>
                                <div className="webinar-item__grid">
                                    <h1 className="h2">{data.title}</h1>
                                    <div className="webinar-item__notes">
                                        <p className="webinar-item__date">{courseData}</p>
                                        {data.online ? <span className="webinar-item__note webinar-item__note--online">Онлайн встречи</span> : null}
                                        {data.demo ? <span className="webinar-item__note webinar-item__note--demo">Демоверсия в подарок </span> : null}
                                    </div>

                                    <div className="webinar-item__btns">
                                        <button className="btn gradient webinar-item__link" onClick={() => setFormShow(true)}>Задать вопрос про курс</button>
                                    </div>
                                    <img src={data.thumbnail} alt={data.title} className="webinar-item__photo" />
                                </div>
                                <div className="webinar-item__text">
                                    {parse(data.text)}
                                </div>
                            </section>
                            {(data.feedbacks?.length > 0) &&
                                <section className="course-reviews">
                                    <div className="container">
                                        <h2 className="course-reviews__title">Отзывы УЧАСТНИКОВ</h2>
                                        <div className="course-reviews__inner">
                                            <Swiper
                                                className="course-reviews__slider"
                                                modules={[Navigation, Pagination]}
                                                spaceBetween={0}
                                                slidesPerView={1}
                                                pagination={{
                                                    el: '.swiper-pagination',
                                                }}
                                                navigation={{
                                                    nextEl: '.swiper-button-next',
                                                    prevEl: '.swiper-button-prev',
                                                }}
                                                breakpoints={{
                                                    '681': {
                                                        slidesPerView: 2,
                                                        spaceBetween: 15,
                                                    },
                                                    '769': {
                                                        slidesPerView: 2,
                                                        spaceBetween: 15,
                                                    },
                                                    '1200': {
                                                        slidesPerView: 2,
                                                        spaceBetween: 35,
                                                    },
                                                }}
                                                loop={false}
                                                lazy>
                                                {data.feedbacks.map((review, idx) => (
                                                    <SwiperSlide key={`slide_${idx}`}>
                                                        <Review review={review} showReview={showReview} />
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                            <div className="swiper-pagination"></div>
                                            <div className="swiper-button-prev"></div>
                                            <div className="swiper-button-next"></div>
                                        </div>
                                    </div>
                                </section>
                            }
                        </>
                        : <p>Ошибка получения данных</p>
                    }
                </>
            </LoadingBlock >
            
            {reviewShow && <ModalPopup setShow={setReviewShow}>
                <ReviewPopup review={currentReview} />
            </ModalPopup>}
            {formShow && <PopupForm setShow={setFormShow}/>}
            
            <div className="webinar-item__bottom">
                {data
                    ? <>
                    <section className="webinar-item_buy">
                        <div className="container">
                            <h2>Купить курс </h2>
                        </div>
                        <div className="container webinar-item_buy__row">
                            <div className="webinar-item_buy__text">
                                <h2>{data.title}</h2>
                                <p className="webinar-item__date">{courseData}</p>
                                <div className="webinar-item__btns">
                                    {!!data.price && <span className="webinar-item__price">Купить за <b>{FormatPrice(data.price)} ₽</b></span>}                            
                                </div>
                            </div>
                            <div className="webinar-item_buy__form">
                                <BuyForm
                                    booking={true}
                                    type="buy_course"
                                    bookingSlotId={courseId}
                                    onBookError={handleFormError}
                                    onBookSuccess={handleFormSuccess}
                                    btnText='Оплатить' />
                            </div>
                        </div>
                    </section>
                    </> : null
                }
                <div className="webinar-item__faq">
                    <div className="container">
                        {currentData
                            ? <div className="faq-list">
                                <h2>Вопрос-Ответ</h2>
                                {currentData.map((item: FAQItem) => {
                                    return (
                                        <details className="faq-list__item" key={item.id}>
                                            <summary>{item.question}</summary>
                                            <p>{item.answer}</p>
                                        </details>
                                    );
                                })}
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
            
            {resultShow && <ModalPopup setShow={handleCloseAllPopup} addedClass="form_result">
                <FormResult status={resultText.status} text={resultText.text} />
            </ModalPopup>}
            
        </>
    )
}
export default CoursePage;