const FormatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Так как нумерация месяцев начинается с 0, мы добавляем 1
    const year = date.getFullYear();

    const monthForList = date.getMonth();
    const monthsArray = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря"
    ];

    const dateDMY = `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
    const dateDM = `${day} ${monthsArray[monthForList]} ${year}г.`;

    return { dateDMY, dateDM };
}
export default FormatDate;