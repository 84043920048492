import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import LoadingBlock from "shared/LoadingBlock";
import HeadTags from "shared/HeadTags";
import ConsultForm from "shared/ConsultForm";
import { useGetBlogByIdQuery } from "services/api/api";
import ym from 'react-yandex-metrika';

import "yet-another-react-lightbox/styles.css";
import 'swiper/css';

const BlogItemPage = ({ setPageClass }) => {
    const {slug} = useParams();
    const { data, isLoading } = useGetBlogByIdQuery(slug);

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    useEffect(() => {
        setPageClass('blog-item-page');
        ym('hit', `/blog/${slug}`);
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <section className="news-item news-item--blog container">
            <HeadTags
                title={data ? data.seo_title : 'Блог'}
                metaDescription={data ? data.seo_description : 'Блог'} />
            <LoadingBlock isLoading={isLoading}>
                <>
                    {data
                        ? <>
                            <ul className="breadcrumbs">
                                <li><Link to="/">главная</Link></li>
                                <li><Link to="/blog">Блог</Link></li>
                                <li><span>{data.title}</span></li>
                            </ul>
                            <h1 className="h2">{data.title}</h1>
                            {data.short_text ? <div className="news-item__lead">{parse(data.short_text)}</div> : null}
                            <img src={data.image} alt={data.title} className="news-item__photo" />
                            <div className="news-item__text">
                                {parse(data.text)}
                                <span onClick={goBack} className="news-item__back btn">Вернуться в блог</span>
                            </div>
                        </>
                        : <p>Ошибка получения данных</p>}
                    <ConsultForm />
                </>
            </LoadingBlock>
        </section>
    )
}
export default BlogItemPage;