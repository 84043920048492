import React from 'react';
import Select from 'react-select';
import { useField } from 'formik';

type Option = {
    value: string,
    label: string
};

type Props = {
  selectOptions: Option[],
  formikFieldName: string,
  placeholder?: string,
};

const FormikSelect = ({
  selectOptions,
  formikFieldName,
  placeholder,
}: Props) => {
  // eslint-disable-next-line no-unused-vars
  const [field, _, helpers] = useField(formikFieldName);
  const { setValue } = helpers;

  return (
    <Select
      defaultValue={selectOptions.find(
        (option) => option.value === field.value
      )}
      className="select"
      classNamePrefix="select"
      options={selectOptions}
      placeholder={placeholder}
      onBlur={field.onBlur}
      onChange={(option) => setValue(option.value)}
    />
  );
};

FormikSelect.defaultProps = {
  placeholder: '',
};

export default FormikSelect;