import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import HeadTags from "shared/HeadTags";
import ym from 'react-yandex-metrika';
import { useGetUserDataQuery } from "services/api/api";
import Spinner from 'shared/Spinner';
import { UserAppointment } from 'services/api/types/User';
import AccountForm from './AccountForm';
import FormatDate from "lib/FormatDate";

const BookingPage = ({ setPageClass }: { setPageClass?: (pageClass: string) => void }) => {
	const { id } = useParams();
    const navigate = useNavigate();
	const { data, error, isLoading } = useGetUserDataQuery();
	const appointment: UserAppointment = data ? data.appointments.find(appointment => appointment.timeslot.id === Number(id)) : null;

	useEffect(() => {
		setPageClass('account-page');
		ym('hit', `/lk/my-orders/appointments/${id}`);
	}, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}, [navigate]);

	return (
		<div className="order-item">
			{isLoading
				? <Spinner />
				: error
					? <p>Ошибка получения данных</p>
					: <>
						<HeadTags
							title={`Личная консультация`}
							metaDescription={`Личная консультация (${appointment ? appointment.employee_name : ''})`}
						/>
						<h2 className="order-item__title order-item__title--booking">Личная консультация {appointment ? `(${appointment.employee_name})` : ''}</h2>
						<p style={{marginBottom: '10px'}}>Дата: {FormatDate(appointment.date).dateDMY} / Время: {appointment.timeslot.start_time.slice(0, -3)}, MSK / Сессия: 50 мин</p>
                        <p>1. Подготовка к консультации. Перед консультацией рекомендуется создать комфортную обстановку, где Вы будете чувствовать себя спокойно и вас не будут отвлекать.</p>
                        <p>2. Онлайн консультация. В назначенное время Вам нужно присоединиться к видеоконференции Zoom, используя предоставленную ссылку. Встреча начинается с приветствия и установления контакта с психологом. Затем вы можете свободно обсуждать свои вопросы, проблемы или темы, которые вас беспокоят. Психолог будет слушать вас, задавать уточняющие вопросы и предлагать подходящие стратегии и решения.</p>
                        <p>3. Отмена записи и опоздания. Если Вам необходимо отменить запись на консультацию, пожалуйста, предупредите нас за 24 часа до ее начала без каких-либо штрафов. Если Вы не предупредили нас об этом или хотите перенести встречу менее чем за 24 часа до ее начала, то оплата не возвращается. Если Вы опоздали на согласованные встречи без уведомления за 24 часа до начала встречи, то оплата не возвращается.</p>
                        <p>Если у вас есть еще вопросы, не стесняйтесь задавать. Вы можете связаться с нами по адресу электронной почты: <a href="mailto:info@psyterapevt.ru">info@psyterapevt.ru</a></p>
                        <AccountForm data={data}/>
                    </>
			}
		</div>
	);
};
export default BookingPage;