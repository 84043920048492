import { useParams } from 'react-router-dom';
import { Dictionary } from '@reduxjs/toolkit';

let prevParams: Dictionary<number> = {};
const useParamInt: (key: string) => [number, boolean] = (key) => {
  const params = useParams();
  const val = params[key] ? parseInt(params[key]) : null;
  const changed = prevParams[key] !== undefined && prevParams[key] !== val;
  prevParams[key] = val;
  return [val, changed];
};

export default useParamInt;

const useGetCookie = (name: string) => {
  const matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export { useGetCookie };