import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

const DOTS = 9999;

const range = (start: number, end: number) => {
	let length = end - start + 1;
	return Array.from({ length }, (_, idx) => idx + start);
};

const usePagination = ({
	pageSize,
	totalCount,
	siblingCount = 1,
	currentPage
}: {
	pageSize: number,
	totalCount: number,
	siblingCount: number,
	currentPage: number
}) => {
	const paginationRange = useMemo(() => {
		// Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
		const totalPageNumbers = siblingCount + 5;
		const totalPageCount = Math.ceil(totalCount / pageSize);
		/*
		If the number of pages is less than the page numbers we want to show in our
		paginationComponent, we return the range [1..totalPageCount]
		*/
		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}
		
		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(
			currentPage + siblingCount,
			totalPageCount
		);
	
		/*
			We do not want to show dots if there is only one position left 
			after/before the left/right page count as that would lead to a change if our Pagination
			component size which we do not want
		*/
		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;
	
		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;
	
		if (!shouldShowLeftDots && shouldShowRightDots) {
			let leftItemCount = 3 + 2 * siblingCount;
			let leftRange = range(1, leftItemCount);
			return [...leftRange, DOTS, totalPageCount];
		}
	
		if (shouldShowLeftDots && !shouldShowRightDots) {
			let rightItemCount = 3 + 2 * siblingCount;
			let rightRange = range(
				totalPageCount - rightItemCount + 1,
				totalPageCount
			);
			return [firstPageIndex, DOTS, ...rightRange];
		}
	
		if (shouldShowLeftDots && shouldShowRightDots) {
			let middleRange = range(leftSiblingIndex, rightSiblingIndex);
			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
		}
	}, [pageSize, totalCount, siblingCount, currentPage]);
  
	return paginationRange;
};

const Pagination = ({
	onPageChange,
	totalCount,
	pageSize,
	siblingCount = 1,
	currentPage
}: {
	onPageChange: (number) => void,
	totalCount: number,
	pageSize: number,
	siblingCount?: number,
	currentPage: number
}): JSX.Element => {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize
	});

	// If there are less than 2 times in pagination range we shall not render the component
	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	let lastPage = paginationRange[paginationRange.length - 1];

	return (
		<ul className="pagination">
			{/* Left navigation arrow */}
			<li className={`${currentPage === 1 ? 'disabled': ''}`} onClick={onPrevious}>
				<span className="pagination__arrow pagination__arrow--prev" title="предыдущая страница">&lt;</span>
			</li>
			{paginationRange.map(pageNumber => {
				// If the pageItem is a DOT, render the DOTS unicode character
				if (pageNumber === DOTS) {
				return <li key={uuidv4()}><span>&#8230;</span></li>;
				}
				// Render our Page Pills
				return (
				<li className={`${ pageNumber === currentPage ? 'active' : ''}`} onClick={() => onPageChange(pageNumber)} key={uuidv4()}>
					<span>{pageNumber}</span>
				</li>
				);
			})}
			{/*  Right Navigation arrow */}
			<li className={`${currentPage === lastPage ? 'disabled': ''}`} onClick={onNext}>
				<span className="pagination__arrow pagination__arrow--prev" title="следующая страница">&gt;</span>
			</li>
			<li className="pagination__all" onClick={() => onPageChange(Infinity)}>
				<span className="btn gradient">Показать все</span>
			</li>
		</ul>
	);
};

export default Pagination;