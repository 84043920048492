import HeadTags from "shared/HeadTags";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useGetTeamListQuery } from 'services/api/api';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useNavigate } from "react-router-dom";
import kons_1 from 'assets/images/kons_1.jpg';
import LoadingBlock from "shared/LoadingBlock";
import FormatPrice from "lib/FormatPrice";
import PopupForm from "shared/PopupForm";
import ym from 'react-yandex-metrika';

const TeamItem = ({person}) => {
    return (
        <li className="main-team-item">
            <Link to={`/komanda/${person.slug}`} className="main-team-item__photo">
                <img src={person.thumbnail} alt={`${person.name} ${person.surname}`}/>
				<span className="main-team-item__name">{person.name} <span className="main-team-item__name-right">{person.surname}</span></span>
			</Link>
            <Link to={`/komanda/${person.slug}`} className="team-list-item__desc">
                <span className="team-list-item__position">{person.position}</span>
                <span className="team-list-item__price"><span>{person.duration}</span> <b>{FormatPrice(person.fee)} ₽</b></span>
                <span className="main-team-item__more gradient btn">ПОДРОБНЕЕ</span>
            </Link>
        </li>
    );
};
const ConsultationPage = ({setPageClass}) => {
    const { data, isLoading } = useGetTeamListQuery();
    const navigate = useNavigate();
    const [formShow, setFormShow] = useState(false);

    useEffect(() => {
        setPageClass('about-bg');
        ym('hit', '/uslugi/lichnoe-konsultirovanie');
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

	return (
		<section className="personal-consultation container">
            <HeadTags
				title='Личное консультирование с психологом - Елена Полянская'
				metaDescription='Личное консультирование с психологом предоставляет возможность получить профессиональную помощь в решении различных личных и эмоциональных проблем. Наши психологи помогут вам разобраться в своих чувствах, улучшить качество жизни и справиться с трудностями.'
			/>
            <ul className="breadcrumbs">
                <li><Link to="/">главная</Link></li>
                <li><Link to="/uslugi">УСЛУГИ</Link></li>
                <li><span>Личное консультирование</span></li>
            </ul>
            <h1 className="h2">Онлайн-консультации с психологом</h1>
            <div className="personal-consultation__text">
                <p>Личное Консультирование – индивидуальный подход и 100% моего ресурса и внимания я использую для решения вашей задачи. Мое внимание сосредоточено на вас, на устройстве вашей системы связей и отношений, на личных обстоятельствах и особенностях. </p>
                <p>Я запрашиваю и ищу уникальное решение под конкретную задачу, которая так же неповторима. Нет идентичных проблем, как нет двух похожих ситуаций или людей. Есть похожее проблемное событие или поведение – дети с аутизмом могут быть похожи в разных семьях, процессы в этих семьях проистекают по похожим алгоритмам. Но решение проблемы - всегда уникально, оно может быть только ваше!</p>
            </div>
            <div className="team-item-about">
                <img src={kons_1} className="team-item-about__photo" alt="Анна Сергеевцева"/>
                <div className="team-item-about__block">
                    <h2 className="team-item-about__title">Елена Полянская</h2>
                    <p className="team-item-about__smtitle">С какими темами и вопросами можно прийти?</p>
                    <p>С любыми - да простят мне это маркетологи- я знаю, что решение широкого круга проблем тонет на рынке. Но ни вы, ни я не знаем, из какого места растет сегодняшняя проблема. Если бы знали - ее бы не было. Отправную точку, которая называется словом «проблема» вы опишете на первой встрече.  А дальше составим карту и отправимся в море решений, где тысячи путей.</p>
                    <p>Куда вы хотите прийти в результате будете решать вы, это ваша жизнь.</p>
                    <div className="personal-consultation__btns">
                        <Link to="/elena-polyanskaya" className="personal-consultation__link btn">подробнее</Link>
                        {/* <button className="team-item-about__btn btn gradient" onClick={() => setFormShow(true)}><span>получить консультацию</span></button> */}
                    </div>
                </div>
            </div>
            <h2>Ведущие психологи</h2>
            <LoadingBlock isLoading={isLoading}>
                <ul className="main-team-slider team-list">
                {data
                    ? data.length
                    ? <>{data.filter(person => person.surname !== "Полянская").map(person => {
                        return (
                            <TeamItem person={person} key={`slide_${person.id}`}/>
                        )
                      })}</>
                    : null
                    : 'Загрузка ...'}
                </ul>
            </LoadingBlock>
            {formShow && <PopupForm setShow={setFormShow}/>}
        </section>
	)
};
export default ConsultationPage;
