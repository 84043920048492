import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingBlock from "shared/LoadingBlock";
import HeadTags from "shared/HeadTags";
import ConsultForm from "shared/ConsultForm";
import ModalPopup from "shared/ModalPopup";
import { useGetQuizByIdQuery } from "services/api/api";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import ym from 'react-yandex-metrika';

import 'swiper/css';

const TestPopup = ({item}) => {
    return (
        <div className="popup test-popup">
            <div className="popup__content">
                <div className="test-popup__text">
                    <p className="test-popup__title">{item.title}</p>
                    {parse(item.text)}
                </div>
                <ConsultForm />
            </div>
        </div>
    );
};

const TestPage = ({ setPageClass }) => {
    const {slug} = useParams();
    const { data, isLoading } = useGetQuizByIdQuery(slug);
    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState<number>();
    const navigate = useNavigate();

    const onOptionChange = e => {
        setResult(parseInt(e.target.value));
    };
    const onSubmit = () => {
        if (result !== undefined) {
            setShowResult(true)
        } else {
            return false;
        }
    };

    useEffect(() => {
        setPageClass('test-item-page');
        ym('hit', `/testi/${slug}`);
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <>
            <HeadTags
                title={data ? data.seo_title : 'тесты'}
                metaDescription={data ? data.seo_description : 'тесты'} />
            <LoadingBlock isLoading={isLoading}>
                <>
                    {data
                        ? <section className="test-item container">
                            <ul className="breadcrumbs">
                                <li><Link to="/">главная</Link></li>
                                <li><Link to="/testi">тесты</Link></li>
                                <li><span>{data.title}</span></li>
                            </ul>
                            <h1 className="h2">{data.title}</h1>
                            <div className="test-item__text">{parse(data.text)}</div>
                            <ul className="test-item__list">
                                {data.steps.map((item) => {
                                    return (
                                        <li key={item.id}>
                                            <input
                                                type="checkbox"
                                                name="test"
                                                className="visually-hidden"
                                                id={`test${item.id}`}
                                                value={item.id}
                                                checked={result === item.id}
                                                onChange={onOptionChange} />
                                            <label className="test-item__variant" htmlFor={`test${item.id}`}>
                                                <img src={item.image} alt={item.title} />
                                                <span className="test-item__variant-name">{item.title}</span>
                                            </label>
                                        </li>
                                    )
                                })}

                            </ul>
                            <button 
                                type="button" 
                                className="test-item__submit btn gradient" 
                                onClick={onSubmit}
                                disabled={result === undefined}>
                                Узнать ответ</button>
                        </section>
                        : <p>Ошибка получения данных</p>}
                </>
            </LoadingBlock >
            {showResult && <ModalPopup setShow={setShowResult}>
                <TestPopup item={data.steps.find(el => el.id === result)} />
            </ModalPopup>}
        </>
    )
}
export default TestPage;