import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HeadTags from "shared/HeadTags";
import ym from 'react-yandex-metrika';
import { useCreatePasswordResetFormRequestMutation } from "services/api/api";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

const RecoveryPage = ({setPageClass}: {setPageClass?: (pageClass: string) => void}) => {
	const [createFormRequest] = useCreatePasswordResetFormRequestMutation();
    const validationsSchema = yup.object().shape({
        email: yup.string()
            .email('Введите верный email')
            .required('Обязательно к заполнению'),
    });
    const [resultShow, setResultShow] = useState(false);
    const [resultText, setResultText] = useState({
        status: '',
        text: ''
    });
	useEffect(() => {
		setPageClass('no-bg');
		ym('hit', `/lk/vosstanovlenie-parolya`);
		const _tmr = window['_tmr'] || (window['_tmr'] = []);
    	_tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);

	return (
		<div className="container account">
			<HeadTags
				title='Восстановление пароля'
				metaDescription='Восстановление пароля'
			/>
			<h1 className="h2">личный кабинет</h1>
			<p className="recovery__title">Восстановление пароля</p>
			<Formik
				initialValues={{
					email: ''
				}}
				validateOnBlur
				onSubmit={async ({ email }, {resetForm}) => {
					const body: {email: string} = {
						email
					};
					let res = await createFormRequest({ body });
					if (res['error']) {
						setResultShow(true);
						setResultText({
							status: '',
							text: res['error'].data ?  Object.values(res['error'].data).map((el: string[]) => el.join('\n')).join('\n') : 'Попробуйте позже'
						});
					} else {
						resetForm();
						setResultShow(true);
						setResultText({
							status: 'success',
							text: 'Письмо с инструкциями по восстановлению пароля выслано на вашу почту.'
						});
					}
				}}
				validationSchema={validationsSchema}
			>
				{({
					handleSubmit,
					isSubmitting
				}) => (
					<Form className="form profile-form profile-form--recovery" onSubmit={handleSubmit}>
						<div className="form__field">
							<Field
								type="email"
								name="email"
								placeholder="E-MAIL" /> 
							<ErrorMessage name="email" component="span" className="form__error" />
						</div>
						<button type="submit" disabled={isSubmitting} className="form__btn btn gradient">оТПРАВИТЬ</button>
					</Form>
				)}
			</Formik>
			{resultShow && <div className={`profile-form__result ${resultText.status === 'success' ? 'success' : ''}`}>{resultText.text}</div>}
		</div>
	)
}
export default RecoveryPage;