import { useState, useEffect } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import ym from 'react-yandex-metrika';

const AccountPage = ({setPageClass, setToken, token}: {setPageClass?: (pageClass: string) => void, setToken?: (token: string|undefined) => void, token: string}) => {
	const navigate = useNavigate();
	const logout = () => {
		setToken(undefined);
		localStorage.removeItem('token');
		localStorage.removeItem('refresh');
		navigate('/');
	};
	useEffect(() => {
		if (token === undefined) {
			navigate('/auth');
		}
    }, [navigate, token]);
	useEffect(() => {
		ym('hit', `/lk`);
		const _tmr = window['_tmr'] || (window['_tmr'] = []);
    	_tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);

	return (
		<div className="container account">
			<h1 className="h2">личный кабинет</h1>
			<div className="account__content">
				<aside className="account__menu">
					<NavLink to="/lk/profile">Личные данные</NavLink>
					<NavLink to="/lk/my-orders">Мои покупки</NavLink>
					<NavLink to="/lk/support">Техподдержка</NavLink>
					<button onClick={logout}>Выйти</button>
				</aside>
				<Outlet/>
			</div>
		</div>
	)
}
export default AccountPage;