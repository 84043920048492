import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HeadTags from "shared/HeadTags";
import ym from 'react-yandex-metrika';
import { useGetUserDataQuery } from "services/api/api";
import Spinner from 'shared/Spinner';
import FormatDate from "lib/FormatDate";

// Функция для проверки, что заданная дата уже прошла
function isDatePassed(dateString) {
	// Создаем объект даты из строки
	const givenDate = new Date(dateString);
	// Получаем текущую дату
	const currentDate = new Date();

	// Устанавливаем время обеих дат на начало дня (00:00:00), чтобы сравнение было корректным
	givenDate.setHours(0, 0, 0, 0);
	currentDate.setHours(0, 0, 0, 0);

	// Сравниваем даты
	return givenDate < currentDate;
}

const OrdersPage = ({ setPageClass }: { setPageClass?: (pageClass: string) => void }) => {
	const {data, error, isLoading} = useGetUserDataQuery();
	const coursesOrders = data ? data.courses : [];
	const webinarsOrders = data ? data.webinars : [];
	const appointments = data ? data.appointments : [];

	useEffect(() => {
		ym('hit', `/lk/my-orders`);
		setPageClass('account-page');
	}, []);

	return (
		<div className="orders">
			<HeadTags
				title='Купленные продукты'
				metaDescription='Купленные продукты'
			/>
			<h2 className="orders__title">Купленные продукты</h2>
			{isLoading
				? <Spinner />
				: error
					? <p>Ошибка получения данных</p>
					: <>
						{coursesOrders.length === 0 && webinarsOrders.length === 0 && appointments.length === 0
							? <p>Пока Вы ничего не купили</p>
							: <table className="orders__list">
								<thead>
									<tr>
										<th>Номер заказа</th>
										<th>Название</th>
										<th>Период</th>
										<th>Статус</th>
									</tr>
									{coursesOrders.filter(el => el.hasOwnProperty('course')).map(el => {
										return (
											<tr key={el.course.id}>
												<td>Заказ #{el.purchase_id}</td>
												<td><Link to={`/lk/my-orders/courses/${el.course.slug}`}>{el.course.title}</Link></td>
												<td>
													{el.course.date ? FormatDate(el.course.date).dateDMY : '' }
													{el.course.optional_date_start ? `С ${FormatDate(el.course.optional_date_start).dateDMY}` : ''}
													{el.course.optional_date_end ? ` до ${FormatDate(el.course.optional_date_end).dateDMY}` : ''}
													{!el.course.date && !el.course.optional_date_start && !el.course.optional_date_end ? 'Период не ограничен' : ''}
												</td>
												<td>
													<span className={`orders__item-status ${el.course.status === 'active' ? '':'ended'}`}>{el.course.status === 'active' ? 'Активна':'Завершена'}</span>
												</td>
											</tr>
										)
									})}
									{webinarsOrders.filter(el => el.hasOwnProperty('webinar')).map(el => {
										return (
											<tr key={el.webinar.id}>
												<td>Заказ #{el.purchase_id}</td>
												<td><Link to={`/lk/my-orders/webinars/${el.webinar.slug}`}>{el.webinar.title}</Link></td>
												<td>
													{el.webinar.date ? FormatDate(el.webinar.date).dateDMY : 'Период не ограничен' }
												</td>
												<td>
													<span className={`orders__item-status ${el.webinar.status === 'active' ? '':'ended'}`}>{el.webinar.status === 'active' ? 'Активна':'Завершена'}</span>
												</td>
											</tr>
										)
									})}
									{appointments.map(el => {
										return (
											<tr key={el.timeslot.id}>
												<td>Заказ #{el.purchase_id}</td>
												<td><Link to={`/lk/my-orders/appointments/${el.timeslot.id}`}>Личная консультация</Link></td>
												<td>
													{FormatDate(el.date).dateDMY} {el.timeslot.start_time.slice(0, -3)}
												</td>
												<td>
													<span className={`orders__item-status ${!isDatePassed(el.date) ? '':'ended'}`}>{!isDatePassed(el.date) ? 'Активна':'Завершена'}</span>
												</td>
											</tr>
										)
									})}
								</thead>
							</table>
						}
					</>
			}
		</div>
	);
};
export default OrdersPage;