import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeadTags from "shared/HeadTags";
import { useCreateRegistrationConfirmFormRequestMutation} from 'services/api/api';
import ym from 'react-yandex-metrika';
import { Token } from 'services/api/types/User';

const WelcomePage = ({setPageClass, setToken, status}: {setPageClass?: (pageClass: string) => void, setToken?: (token: Token) => void, status?: string}) => {
	const navigate = useNavigate();
	useEffect(() => {
		setPageClass('no-bg');
    }, []);

	return (
		<div className="container account">
			<HeadTags
				title='Личный кабинет'
				metaDescription='Вы зарегистрировались на сайте Елены Полянской'
			/>
			<h1 className="h2">личный кабинет</h1>
            <p className="welcome__title">Спасибо!</p>
			{status === 'noconfirm'
				? <>
					<p className="welcome__text">На вашу электронную почту отправлено письмо для подтверждения регистрации.</p>
				</>
				: <>
					<p className="welcome__text">Вы зарегистрировались на сайте Елены Полянской<span>Теперь вы можете:</span></p>
					<div className="welcome__btns">
						<Link to="/auth" className="welcome__btns-item welcome__btns-item--orders"><span>Просмотреть состояние ваших заказов</span></Link>
						<Link to="/auth" className="welcome__btns-item welcome__btns-item--profile"><span>Изменить личные данные</span></Link>
					</div>
				</>
			}
		</div>
	)
}
export default WelcomePage;