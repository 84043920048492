import useParamInt from "app/hooks";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetCoursesListQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Pagination from "shared/Pagination";
import HeadTags from "shared/HeadTags";
import FormatDate from "lib/FormatDate";
import FormatPrice from "lib/FormatPrice";
import ym from 'react-yandex-metrika';

const CoursesListItem = ({item}) => {
    const [courseData, setCourseData] = useState(null);
    
    useEffect(()=>{
        setCourseData(convertDate());
    },[]);

    const convertDate = () => {
        if(item.date?.length > 0) {
            return FormatDate(item.date).dateDM;
        } else if (item.no_date_text?.length > 0) {
            return item.no_date_text;
        } else {
            return `${FormatDate(item.optional_date_start).dateDM} - ${FormatDate(item.optional_date_end).dateDM}`;
        }
    }

    return (
        <li>
            <Link to={`/kursi/${item.slug}`} className="webinars-list-item">
                <img 
                    src={item.thumbnail} 
                    alt={item.title} 
                    className="webinars-list-item__photo" />
                <span className="webinars-list-item__text">
                    <span className="webinars-list-item__date">{courseData}</span>
                    <span className="webinars-list-item__name">{item.title}</span>
                    {!!item.price && <span className="webinars-list-item__link">
                        <span className="webinars-list-item__price">Купить<br/> за <b>{FormatPrice(item.price)} ₽</b></span>
                        <span className="btn gradient">подробнее</span>
                    </span>}
                </span>
            </Link>
        </li>
    );
};

const CoursesPage = ({setPageClass}) => {
    const [limit, setLimit] = useState<number>(12);
    const [offset, setOffset] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const location = useNavigate();
    const ref = useRef(null);
    const { data, isLoading } = useGetCoursesListQuery({limit, offset});

    useEffect(() => {
        setOffset(0);
        setPage(1);
        setLimit(12);
    }, [location]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [page]);

    useEffect(() => {
        setPageClass('courses-page');
        ym('hit', '/kursi');
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);

    const list = data
        ? data.results.length
            ? data.results.map(item => {
                return (
                    <CoursesListItem key={item.id} item={item} />
                )
            })
            : 'Здесь пока ничего нет'
        : 'Загрузка...';

    return (
        <section className="webinars container">
            <HeadTags
                title='Курсы по психологии - для психологов и не психологов - Елена Полянская'
                metaDescription='Онлайн-курсы и обучение по психологии. Описание курсов, цены, расписание занятий и контакты'
            />
            <ul className="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
                <li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    <Link to="/" itemProp="item">Главная</Link>
                </li>
                <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem"><span itemProp="item">курсы</span></li>
            </ul>
            <h1 className="h2">курсы</h1>
            <LoadingBlock isLoading={isLoading}>
                <>
                    <ul className="webinars-list" itemScope itemType="http://schema.org/ItemList" ref={ref}>
                        {list}
                    </ul>
                    {data &&
                        <Pagination
                            pageSize={limit}
                            currentPage={page}
                            totalCount={data.count}
                            onPageChange={page => {
                                if (page === Infinity) {
                                    setLimit(data.count);
                                    setOffset(0);
                                    setPage(1);
                                } else {
                                    setOffset((page - 1) * limit);
                                    setPage(page);
                                }
                            }}
                        />
                    }
                </>
            </LoadingBlock>
        </section>
    )
}
export default CoursesPage;