import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeadTags from "shared/HeadTags";
import { useCreateRegistrationConfirmFormRequestMutation} from 'services/api/api';
import ym from 'react-yandex-metrika';

const ConfirmRegisterPage = ({setPageClass, status}: {setPageClass?: (pageClass: string) => void, status?: string}) => {
	const {uid, token} = useParams();
	const navigate = useNavigate();
	const [createFormRequest] = useCreateRegistrationConfirmFormRequestMutation();
	const confirmRegister = async () => {
		let res = await createFormRequest({uid, token});
		if (res['error']) {
			console.log(res['error']);
		} else {
			navigate('/lk/welcome');
		}
	};
	useEffect(() => {
		if (uid && token && (status === 'noconfirm')) {
			confirmRegister();
		}
    }, [uid, token]);
	useEffect(() => {
		setPageClass('no-bg');
    }, []);

	return (
		<div className="container account">
			<HeadTags
				title='Личный кабинет'
				metaDescription='Восстановление данных'
			/>
			<h1 className="h2">личный кабинет</h1>
            <p className="welcome__title">Спасибо!</p>
			<p className="welcome__text">На вашу электронную почту отправлено письмо для подтверждения регистрации.</p>
		</div>
	)
}
export default ConfirmRegisterPage;