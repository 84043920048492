import { useEffect } from 'react';
import HeadTags from "shared/HeadTags";
import ym from 'react-yandex-metrika';
import { useGetUserDataQuery } from "services/api/api";
import AccountForm from './AccountForm';
import Spinner from 'shared/Spinner';

const SupportPage = ({ setPageClass }: { setPageClass?: (pageClass: string) => void }) => {
	const { data, error, isLoading } = useGetUserDataQuery();

	useEffect(() => {
		setPageClass('account-page');
	}, []);
	useEffect(() => {
		ym('hit', `/lk/support`);
	}, []);

	return (
		<div className="support">
			<HeadTags
				title='Техническая поддержка'
				metaDescription='Техническая поддержка'
			/>
			<h2 className="support__title">Техническая поддержка</h2>
			<p>Уважаемые пользователи! Если у вас возникли трудности или вопросы, наша команда технической поддержки всегда готова помочь.</p>
			<p>Вы можете связаться с нами по адресу электронной почты: <a href="mailto:support@psyterapevt.ru">support@psyterapevt.ru</a><br /> или  заполните форму и отправьте сообщение. Наша команда ответит на ваш запрос как можно скорее.</p>
			<p>Спасибо за обращение и приятного использования нашего сервиса!</p>
			{isLoading
				? <Spinner />
				: error
					? <p>Ошибка получения данных</p>
					: <AccountForm data={data}/>
			}
		</div>
	);
};
export default SupportPage;