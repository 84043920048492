import useParamInt from "app/hooks";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetQuizListQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Pagination from "shared/Pagination";
import HeadTags from "shared/HeadTags";
import { QuizListItem } from "services/api/types/News";
import ym from 'react-yandex-metrika';

const TestsListItem = ({item}: {item: QuizListItem}) => {
    return (
        <li>
            <Link to={`/testi/${item.slug}`} className="test-list-item">
                <img 
                    src={item.image}
                    alt={item.title}
                    className="test-list-item__photo"/>
                <span className="test-list-item__text">
                    <span className="test-list-item__name">{item.title}</span>
                    <span className="test-list-item__link btn gradient">Пройти тест</span>
                </span>
            </Link>
        </li>
    );
};

const TestsPage = ({ setPageClass }) => {
    const [limit, setLimit] = useState<number>(12);
    const [offset, setOffset] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const location = useNavigate();
    const ref = useRef(null);
    const { data, isLoading } = useGetQuizListQuery({limit, offset});

    useEffect(() => {
        setOffset(0);
        setPage(1);
        setLimit(12);
    }, [location]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [page]);

    useEffect(() => {
        setPageClass('webinars-page');
        ym('hit', `/testi`);
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);

    const list = data
        ? data.results.length
            ? data.results.map(item => {
                return (
                    <TestsListItem key={item.id} item={item} />
                )
            })
            : 'Раздел в разработке'
        : 'Загрузка...';

    return (
        <>
            <HeadTags
                title='Тесты психологические - Елена Полянская'
                metaDescription='Психологические тесты онлайн. Бесплатно. Описание сразу.'
            />
            <section className="test container">
                <ul className="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
                    <li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                        <Link to="/" itemProp="item">Главная</Link>
                    </li>
                    <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem"><span itemProp="item">тесты</span></li>
                </ul>
                <h1 className="h2">тесты</h1>
                <LoadingBlock isLoading={isLoading}>
                    <>
                        <ul className="test-list" ref={ref}>
                            {list}
                        </ul>
                        {data &&
                            <Pagination
                                pageSize={limit}
                                currentPage={page}
                                totalCount={data.count}
                                onPageChange={page => {
                                    if (page === Infinity) {
                                        setLimit(data.count);
                                        setOffset(0);
                                        setPage(1);
                                    } else {
                                        setOffset((page - 1) * limit);
                                        setPage(page);
                                    }
                                }}
                            />
                        }
                    </>
                </LoadingBlock>
            </section>
        </>
    )
}
export default TestsPage;