import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingBlock from "shared/LoadingBlock";
import HeadTags from "shared/HeadTags";
import ConsultForm from "shared/ConsultForm";
import { useGetWebinarByIdQuery, useGetUserDataQuery, usePayWebinarMutation, useBuyWebinarMutation } from "services/api/api";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import FormatPrice from "lib/FormatPrice";
import FormatDate from "lib/FormatDate";
import PopupForm from "shared/PopupForm";
import ModalPopup from "shared/ModalPopup";
import FormResult from "shared/FormResult";
import ym from 'react-yandex-metrika';

import "yet-another-react-lightbox/styles.css";
import 'swiper/css';

const WebinarPage = ({ setPageClass }) => {
    const {slug} = useParams();
    const { data, isLoading } = useGetWebinarByIdQuery(slug);
    const navigate = useNavigate();
    const [formShow, setFormShow] = useState(false);
    const { data: userData } = useGetUserDataQuery();
    const [payRequest] = usePayWebinarMutation();
    const [buyWebinarRequest] = useBuyWebinarMutation();
    const userId = useMemo(() => userData ? userData.id : undefined, [userData]);
    const webinarId = useMemo(() => data ? data.id : undefined, [data]);
    const [resultShow, setResultShow] = useState(false);
    const [payFormShow, setPayFormShow] = useState(false);
    const [resultText, setResultText] = useState({
        status: '',
        text: ''
    });

    useEffect(() => {
        setPageClass('webinar-item-page');
        ym('hit', `/vebinari/${slug}`);
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    const handleFormError = () => {
        setResultShow(true);
        setResultText({
            status: 'Ошибка!',
            text: `Попробуйте позже`
        });
    };
    const handleCloseAllPopup = (show: boolean) => {
        setResultShow(show);
        if (payFormShow) {
            setPayFormShow(show);
        }
    };
    const handleFormSuccess = async (id) => {
        setResultShow(true);
        setResultText({
            status: 'Спасибо!',
            text: `Идет перенаправление на страницу оплаты...`
        });
        const res = await payRequest(id);
        if ("error" in res) {
            handleFormError();
        } if ("data" in res && res.data) {
            window.location.href = res.data.payment_url;
        }
    };
    const handleBuy = async () => {
        if (!webinarId) return;
        if (userId) {
            const res = await buyWebinarRequest({
                webinar_id: webinarId
            });
            if ("error" in res) {
                handleFormError();
            } if ("data" in res && res.data) {
                handleFormSuccess(res.data.id);
            }
        } else {
            setPayFormShow(true);
        }
    };
    
    return (
        <section className="webinar-item container">
            <HeadTags
                title={data ? data.seo_title : 'вебинары'}
                metaDescription={data ? data.seo_description : 'вебинары'} />
            <LoadingBlock isLoading={isLoading}>
                <section className="container team-item">
                    {data
                        ? <>
                            <ul className="breadcrumbs">
                                <li><Link to="/">главная</Link></li>
                                <li><Link to="/vebinari">вебинары</Link></li>
                                <li><span>{data.title}</span></li>
                            </ul>
                            <div className="webinar-item__grid">
                                <h1 className="h2">{data.title}</h1>
                                <p className="webinar-item__date">{data.date?.length > 0 ? `${FormatDate(data.date).dateDM}` : data.no_date_text}</p>
                                <div className="webinar-item__btns">
                                    {!!data.price && <span className="webinar-item__price">Купить за <b>{FormatPrice(data.price)} ₽</b></span>}
                                    <button className="btn gradient webinar-item__link" onClick={handleBuy}>купить курс</button>
                                    <button className="btn gradient webinar-item__link" onClick={() => setFormShow(true)}>Отправить заявку</button>
                                </div>
                                <img src={data.thumbnail} alt={data.title} className="webinar-item__photo" />
                            </div>
                            <div className="webinar-item__text">
                                {parse(data.text)}
                            </div>
                        </>
                        : <p>Ошибка получения данных</p>
                    }
                    <ConsultForm />
                </section>
            </LoadingBlock>
            {formShow && <PopupForm setShow={setFormShow}/>}
            {payFormShow && <ModalPopup setShow={handleCloseAllPopup} addedClass='modal_form'>
                <ConsultForm
                    booking={true}
                    type="buy_webinar"
                    bookingSlotId={webinarId}
                    onBookError={handleFormError}
                    onBookSuccess={handleFormSuccess}
                    btnText='Купить вебинар' />
            </ModalPopup>}
            {resultShow && <ModalPopup setShow={handleCloseAllPopup} addedClass="form_result">
                <FormResult status={resultText.status} text={resultText.text} />
            </ModalPopup>}
            
        </section>
    )
}
export default WebinarPage;