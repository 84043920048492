import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HeadTags from "shared/HeadTags";
import ym from 'react-yandex-metrika';
import { useCreateUpdateUserDataFormRequestMutation, useGetUserDataQuery } from "services/api/api";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import MaskedInput from "react-text-mask";
import ModalPopup from "shared/ModalPopup";
import FormResult from "shared/FormResult";
import FormikSelect from 'shared/FormikSelect';
import { UserData } from 'services/api/types/User';
import Spinner from 'shared/Spinner';

const findEditedFields = (fields: UserData, initialFields: UserData) => {
    let edited = {};
    for (const [key, value] of Object.entries(fields)) {
        if (value != initialFields[key]) {
            edited[key] = value
        }
    };
    return edited;
};
const ProfilePage = ({setPageClass}: {setPageClass?: (pageClass: string) => void}) => {
    const {data, error, isLoading} = useGetUserDataQuery();
	const [createFormRequest] = useCreateUpdateUserDataFormRequestMutation();
	const phoneNumberMask = [
		"+",
		"7",
		" ",
		/\d/,
		/\d/,
		/\d/,
		" ",
		/\d/,
		/\d/,
		/\d/,
		" ",
		/\d/,
		/\d/,
		" ",
		/\d/,
		/\d/
	];
	const validationsSchema = yup.object().shape({
		first_name: yup.string()
			.min(3, 'Слишком короткое имя')
			.matches(
				/[а-я,А-Я]/,
				'Имя должно содержать только русские буквы'
			)
			.matches(/^([^0-9]*)$/gm, "'Имя должно содержать только буквы")
			.required('Обязательно к заполнению'),
        last_name: yup.string()
            .nullable()
			.min(2, 'Слишком короткое значение')
			.matches(
				/[а-я,А-Я]/,
				'Фамилия должна содержать только русские буквы'
			)
			.matches(/^([^0-9]*)$/gm, "'Фамилия должна содержать только буквы"),
        patronymic: yup.string()
            .nullable()
			.min(3, 'Слишком короткое отчество')
			.matches(
				/[а-я,А-Я]/,
				'Отчество должно содержать только русские буквы'
			)
			.matches(/^([^0-9]*)$/gm, "'Отчество должно содержать только буквы"),
		birth_date: yup.date().nullable(),
		phone: yup.string()
			.required('Обязательно к заполнению')
			.transform(value => value.replace(/[^\d]/g, ''))
			.min(11, "Введите корректный номер"),
		email: yup.string()
			.email('Введите верный email')
			.required('Обязательно к заполнению'),
		// password: yup.string()
		// 	.min(6, 'Слишком короткий пароль')
		// 	.required('Обязательно к заполнению'),
		newsletter: yup.bool(),
			// .oneOf([true], 'Дайте согласие на рассылку'),
		check: yup.bool()
			.oneOf([true], 'Дайте согласие на обработку персональных данных'),
	});
	const [resultShow, setResultShow] = useState(false);
	const [resultText, setResultText] = useState({
		status: '',
		text: ''
	});
	const [phoneReadOnly, setPhoneReadOnly] = useState(true);
	const [mailReadOnly, setMailReadOnly] = useState(true);
    const initialValues = data ? {
        first_name: data.first_name,
        last_name: data.last_name,
        patronymic: data.patronymic,
        birth_date: data.birth_date,
        phone: data.phone,
        email: data.email,
        gender: data.gender,
        newsletter: true,
        check: true
    } : {
        first_name: '',
        last_name: '',
        patronymic: '',
        birth_date: '',
        phone: '',
        email:'',
        gender: null,
        newsletter: true,
        check: true
    };

	useEffect(() => {
		setPageClass('profile-page');
    }, []);
	useEffect(() => {
        ym('hit', `/lk/profile`);
    }, []);

	return (
		<div className="personal-data">
			<HeadTags
				title='Личные данные'
				metaDescription='Личные данные'
			/>
			<h2 className="personal-data__title">Личные данные</h2>
            {isLoading 
                ?  <Spinner/>
                :  error 
                    ? <p>Ошибка получения данных</p>
                    : <Formik
                        initialValues={initialValues}
                        validateOnBlur
                        onSubmit={async (fields, {resetForm}) => {
                            let body: UserData = findEditedFields(fields, initialValues);
                            let res = await createFormRequest({ body });
                            if (res['error']) {
                                setResultShow(true);
                                setResultText({
                                    status: 'Ошибка!',
                                    text: `Попробуйте позже`
                                });
                            } else {
                                setPhoneReadOnly(true);
                                setMailReadOnly(true);
                                setResultShow(true);
                                setResultText({
                                    status: 'Спасибо!',
                                    text: `Данные успешно обновлены`
                                });
                            }
                        }}
                        validationSchema={validationsSchema}
                    >
                        {({
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <Form className="form profile-form profile-form--edit" onSubmit={handleSubmit}>
                                <div className="form__field">
                                    <label htmlFor="surname">Фамилия</label>
                                    <Field
                                        type="text"
                                        id="surname"
                                        name="last_name"/>
                                    <ErrorMessage name="last_name" component="span" className="form__error" />
                                </div>
                                <div className="form__field">
                                    <label htmlFor="name">Имя</label>
                                    <Field
                                        type="text"
                                        name="first_name"
                                        id="name" />
                                    <ErrorMessage name="first_name" component="span" className="form__error" />
                                </div>
                                <div className="form__field">
                                    <label htmlFor="name2">Отчество</label>
                                    <Field
                                        id="name2"
                                        type="text"
                                        name="patronymic" />
                                    <ErrorMessage name="patronymic" component="span" className="form__error" />
                                </div>
                                <div className="form__field">
                                    <label htmlFor="birth">ДАТА РОЖДЕНИЯ</label>
                                    <Field
                                        type="date"
                                        id="birth"
                                        name="birth_date"/>
                                    <ErrorMessage name="birth_date" component="span" className="form__error" />
                                </div>
                                <div className="form__field">
                                    <label htmlFor="gender">ПОЛ</label>
                                    <FormikSelect
                                        selectOptions={[
                                            { value: 'F', label: 'Жен.' },
                                            { value: 'M', label: 'Муж.' },
                                        ]}
                                        formikFieldName="gender"
                                    />
                                    <ErrorMessage name="gender" component="span" className="form__error" />
                                </div>
                                <div className="form__field form__field--row">
                                    <label htmlFor="phone">ТЕЛЕФОН</label>
                                    <Field 
                                        name="phone" 
                                        id="phone">
                                        {({ field }) => <MaskedInput
                                            {...field}
                                            mask={phoneNumberMask}
                                            type="tel"
                                            readOnly={phoneReadOnly}
                                        />
                                        }
                                    </Field>
                                    <button type="button" onClick={() => setPhoneReadOnly(false)}>Изменить</button>
                                    <ErrorMessage name="phone" component="span" className="form__error" />
                                </div>
                                <div className="form__field form__field--row">
                                    <label htmlFor="email">E-MAIL</label>
                                    <Field
                                        type="email"
                                        id="email"
                                        name="email"
                                        readOnly={mailReadOnly}/> 
                                    <button type="button" onClick={() => setMailReadOnly(false)}>Изменить</button>
                                    <ErrorMessage name="email" component="span" className="form__error" />
                                </div>
                                <div>
                                    <div className="form__check">
                                        <Field
                                            className="visually-hidden"
                                            name="newsletter"
                                            type="checkbox"
                                            checked
                                            id="newsletter" />
                                        <label htmlFor="newsletter"><span>Согласие на рассылку</span></label>
                                    </div>
                                    <div className="form__check">
                                        <Field
                                            className="visually-hidden"
                                            name="check"
                                            type="checkbox"
                                            checked
                                            id="about_check" />
                                        <label htmlFor="about_check"><span>Я согласен с <Link to="">политикой конфиденциальности и обработки персональных данных</Link></span></label>
                                    </div>
                                </div>
                                <button type="submit" disabled={isSubmitting} className="form__btn btn gradient">сохранить</button>
                            </Form>
                        )}
                    </Formik>
            }
            {resultShow && <ModalPopup setShow={setResultShow} addedClass="form_result">
                <FormResult status={resultText.status} text={resultText.text}/>
            </ModalPopup>}
		</div>
	)
}
export default ProfilePage;