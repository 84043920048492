import HeadTags from "shared/HeadTags";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import ym from 'react-yandex-metrika';

const ServicesPage = ({setPageClass}) => {
    const navigate = useNavigate();

    useEffect(() => {
        setPageClass('services-page');
        ym('hit', '/uslugi');
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

	return (
		<section className="services">
            <HeadTags
				title='Услуги психолога - Елена Полянская'
				metaDescription='Личное консультирование, групповая терапия, обучение. Вебинары и курсы. Ретриты.'
			/>
            <div className="container">
                <ul className="breadcrumbs">
                    <li><Link to="/">главная</Link></li>
                    <li><span>УСЛУГИ</span></li>
                </ul>
                <h1 className="h2">Услуги</h1>
                <ul className="main-services-list">
                    <li>
                        <Link to="/uslugi/lichnoe-konsultirovanie" className="main-services-list__item">
                            <span className="main-services-list__item-name">Личное консультирование </span>
                        </Link>
                        <Link to="/uslugi/lichnoe-konsultirovanie" className="services__item-link btn gradient">ПОДРОБНЕЕ </Link>
                    </li>
                    <li>
                        <Link to="/uslugi/gruppovaya-terapiya" className="main-services-list__item">
                            <span className="main-services-list__item-name">Групповая терапия</span>
                        </Link>
                        <Link to="/uslugi/gruppovaya-terapiya" className="services__item-link btn gradient">ПОДРОБНЕЕ </Link>
                    </li>
                    <li>
                        <Link to="/uslugi/obuchauschie-gruppi" className="main-services-list__item">
                            <span className="main-services-list__item-name">Обучающие группы</span>
                        </Link>
                        <Link to="/uslugi/obuchauschie-gruppi" className="services__item-link btn gradient">ПОДРОБНЕЕ </Link>
                    </li>
                    <li>
                        <Link to="/uslugi/retriti" className="main-services-list__item">
                            <span className="main-services-list__item-name">Ретриты</span>
                        </Link>
                        <Link to="/uslugi/retriti" className="services__item-link btn gradient">ПОДРОБНЕЕ </Link>
                    </li>
                    <li>
                        <Link to="/vebinari" className="main-services-list__item">
                            <span className="main-services-list__item-name">Вебинары</span>
                        </Link>
                        <Link to="/vebinari" className="services__item-link btn gradient">ПОДРОБНЕЕ </Link>
                    </li>
                    <li>
                        <Link to="/kursi" className="main-services-list__item">
                            <span className="main-services-list__item-name">Курсы</span>
                        </Link>
                        <Link to="/kursi" className="services__item-link btn gradient">ПОДРОБНЕЕ </Link>
                    </li>
                </ul>
            </div>
        </section>
	)

};
export default ServicesPage;
