import HeadTags from "shared/HeadTags";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import group_1 from 'assets/images/group_1.jpg';
import group_list_1 from 'assets/images/group_list_1.jpg';
import group_list_2 from 'assets/images/group_list_2.jpg';
import group_list_3 from 'assets/images/group_list_3.jpg';
import PopupForm from "shared/PopupForm";
import ym from 'react-yandex-metrika';

const GroupPage = ({setPageClass}) => {
    const navigate = useNavigate();
    const [formShow, setFormShow] = useState(false);

    useEffect(() => {
        setPageClass('about-bg');
        ym('hit', '/uslugi/gruppovaya-terapiya');
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

	return (
		<section className="group container">
            <HeadTags
				title='Групповая терапия с психологом - Елена Полянская'
				metaDescription='В терапевтической группе работает ведущий и групповой процесс. В группе можно увидеть отражение своих проблем через проблемы другого.  '
			/>
            <ul className="breadcrumbs">
                <li><Link to="/">главная</Link></li>
                <li><Link to="/uslugi">УСЛУГИ</Link></li>
                <li><span>Групповая терапия</span></li>
            </ul>
            <h1 className="h2">Групповая терапия</h1>
            <div className="team-item-about">
                <img src={group_1} className="team-item-about__photo" alt="Елена Полянская"/>
                <div className="team-item-about__block">
                    <p className="team-item-about__smtitle">В терапевтической группе работает ведущий и групповой процесс.  В группе обостряются и обнажаются невидимые в одиночестве или в паре ситуации, события, способы взаимодействия, трудности. </p>
                    <p>В группе можно увидеть отражение своих проблем через проблемы другого. Побыть на безопасном расстоянии, попробовать подступиться через другого к своему неосознанному. Это хорошо на начальных этапах изучения психологии, если вы пока не пробовали на вкус психологию и психотерапию, не были у психолога, а только начали изучать, читать и разбираться в теме. В группе можно раствориться и затеряться, посмотреть издалека на то, как устроен процесс. Получить ресурс безопасности и взять то, к чему вы сегодня готовы.</p>
                </div>
            </div>
            <h2>Виды групп </h2>
            <ul className="group-list">
                {/* <li>
                    <Link to="/uslugi/obuchauschie-gruppi" className="group-list-item">
                        <img src={group_list_1} alt="Обучающие группы" className="group-list-item__photo"/>
                        <span className="group-list-item__name">Обучающие<br/> группы</span>
                        <span className="group-list-item__more gradient btn">ПОДРОБНЕЕ</span>
                    </Link>
                </li> */}
                <li>
                    <div className="group-list-item">
                        <img src={group_list_2} alt="Терапевтические группы" className="group-list-item__photo"/>
                        <span className="group-list-item__name">Терапевтические<br/> группы</span>
                        <span className="group-list-item__more gradient btn" onClick={() => setFormShow(true)}>ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ</span>
                    </div>
                </li>
                <li>
                    <div className="group-list-item">
                        <img src={group_list_3} alt="Расстановочные группы" className="group-list-item__photo"/>
                        <span className="group-list-item__name">Расстановочные<br/> группы</span>
                        <span className="group-list-item__more gradient btn" onClick={() => setFormShow(true)}>ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ</span>
                    </div>
                </li>
            </ul>
            {formShow && <PopupForm setShow={setFormShow}/>}
        </section>
	)

};
export default GroupPage;
