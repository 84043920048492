import { NavLink } from 'react-router-dom';

const Menu = () => {
  return (
    <ul className="menu" itemScope itemType="http://schema.org/SiteNavigationElement">
      <li><NavLink to="/" itemProp="url">Главная</NavLink></li>
      <li><NavLink to="/komanda" itemProp="url">Команда</NavLink></li>
      <li><NavLink to="/uslugi" itemProp="url">Услуги</NavLink></li>
      <li><NavLink to="/kursi" itemProp="url">Курсы</NavLink></li>
      <li><NavLink to="/vebinari" itemProp="url">Вебинары</NavLink></li>
      <li><NavLink to="/testi" itemProp="url">Тесты</NavLink></li>
      <li><NavLink to="/novosti" itemProp="url">Новости</NavLink></li>
      <li><NavLink to="/blog" itemProp="url">Блог</NavLink></li>
      <li><NavLink to="/video" itemProp="url">Видео</NavLink></li>
      <li><NavLink to="/poddergka" itemProp="url">Поддержка</NavLink></li>
      <li><NavLink to="/kontakty" itemProp="url">Контакты</NavLink></li>
    </ul>
  );
};

export default Menu;