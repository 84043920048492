import useParamInt from "app/hooks";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetBlogListQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Pagination from "shared/Pagination";
import HeadTags from "shared/HeadTags";
import 'swiper/css';
import { NewsItem } from "services/api/types/News";
import ym from 'react-yandex-metrika';

const BlogListItem = ({item}: {item: NewsItem}) => {
    return (
        <li>
            <Link to={`/blog/${item.slug}`} className="news-list-item">
                <img 
                    src={item.thumbnail}
                    alt={item.title}
                    className="news-list-item__photo" />
                <span className="news-list-item__name">{item.title}</span>
                <span className="news-list-item__text">{item.lead}</span>
                <span className="news-list-item__link"><span className="btn gradient">читать</span></span>
            </Link>
        </li>
    );
};

const BlogPage = ({setPageClass}) => {
    const [limit, setLimit] = useState<number>(12);
    const [offset, setOffset] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const location = useNavigate();
    const ref = useRef(null);
    const { data, isLoading } = useGetBlogListQuery({limit, offset});

    useEffect(() => {
        setOffset(0);
        setPage(1);
        setLimit(12);
    }, [location]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [page]);

    useEffect(() => {
        setPageClass('news-page');
        ym('hit', '/blog');
        const _tmr = window['_tmr'] || (window['_tmr'] = []);
        _tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);

    const newsList = data
        ? data.results.length
            ? data.results.map(item => {
                return (
                    <BlogListItem key={item.id} item={item} />
                )
            })
            : 'Здесь пока нет записей'
        : <li>Загрузка ...</li>;

    return (
        <section className="news container">
            <HeadTags
                title='Личный блог Елены Полянской'
                metaDescription='Читая мои статьи, можно понять что-то о себе, о своей психике, о своих отношениях в семье и на работе и о том, как и с чем я работаю.'
            />
            <ul className="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
                <li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    <Link to="/" itemProp="item">Главная</Link>
                </li>
                <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem"><span itemProp="item">Блог</span></li>
            </ul>
            <h1 className="h2">Личный блог Елены Полянской</h1>
            <p className="blog__text">Читая мои статьи, можно понять что-то о себе, о своей психике, о своих отношениях в семье и на работе и о том, как и с чем я работаю.</p>
            <LoadingBlock isLoading={isLoading}>
                <>
                    <ul className="news-list news-list--blog" itemScope itemType="http://schema.org/ItemList" ref={ref}>
                        {newsList}
                    </ul>
                    {data &&
                        <Pagination
                            pageSize={limit}
                            currentPage={page}
                            totalCount={data.count}
                            onPageChange={page => {
                                if (page === Infinity) {
                                    setLimit(data.count);
                                    setOffset(0);
                                    setPage(1);
                                } else {
                                    setOffset((page - 1) * limit);
                                    setPage(page);
                                }
                            }}
                        />
                    }
                </>
            </LoadingBlock>
        </section>
    )
}
export default BlogPage;