const ModalPopup = ({setShow, children, addedClass}: {setShow: (boolean) => void, children: JSX.Element, addedClass?: string}): JSX.Element => {
	
	return (
		<div className={`modal ${addedClass}`} onClick={() => setShow(false)}>
			<div className="modal__inner" onClick={e => e.stopPropagation()}>
				{children}
				<button type="button" className="modal__close" title="Close" onClick={() => setShow(false)}>
					<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><g stroke='none' strokeWidth='1px' fill='none' fillRule='evenodd' strokeLinecap='square'><g transform='translate(1.000000, 1.000000)' stroke='#000'><path d='M0,11 L22,11'></path><path d='M11,0 L11,22'></path></g></g></svg>
				</button>
			</div>
		</div>
	);
  };
  
  export default ModalPopup;