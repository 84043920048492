import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingBlock from "shared/LoadingBlock";
import Lightbox from "yet-another-react-lightbox";
import HeadTags from "shared/HeadTags";
import ConsultForm from "shared/ConsultForm";
import Schedule from "shared/Schedule";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useGetTeamItemByIdQuery } from "services/api/api";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import FormatPrice from "lib/FormatPrice";
import ModalPopup from "shared/ModalPopup";
import ym from 'react-yandex-metrika';

import "yet-another-react-lightbox/styles.css";
import 'swiper/css';

const TeamItemPage = ({ setPageClass }) => {
	const {slug} = useParams();
	const { data, isLoading } = useGetTeamItemByIdQuery(slug);
	const navigate = useNavigate();
	const [slotId, setSlotId] = useState<number>();

	const [show, setShow] = useState(false);

	const [galleryIndex, setGalleryIndex] = useState(-1);
	const handleGalleryClick = (index: number) => setGalleryIndex(index);

	useEffect(() => {
		setPageClass('team-item-page');
		ym('hit', `/komanda/${slug}`);
		const _tmr = window['_tmr'] || (window['_tmr'] = []);
    	_tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
	}, []);

	useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

	return (
		<div className="team-item-page">
			<HeadTags
				title={data ? data.seo_title : 'команда'}
				metaDescription={data ? data.seo_description : 'команда'} />
			<LoadingBlock isLoading={isLoading}>
				{data
					? <>
						<section className="container team-item">
							<ul className="breadcrumbs">
								<li><Link to="/">главная</Link></li>
								<li><Link to="/komanda">команда</Link></li>
								<li><span>{`${data.name} ${data.surname}`}</span></li>
							</ul>
							<div className="team-item-about">
								<img src={data.image} className="team-item-about__photo" alt={`${data.name} ${data.surname}`} />
								<div className="team-item-about__block">
									<h1 className="team-item-about__title">{data.name} <span>{data.surname}</span></h1>
									<p className="team-item-about__text">{data.position}</p>
									<div className="team-item-about__bottom">
										<p className="team-item-about__price">
											<span>{data.duration}</span>
											<b>{FormatPrice(data.fee)} ₽</b>
										</p>
										{data.schedules.length > 0 && <button 
											className="team-item-about__btn btn gradient"
											onClick={() => setShow(true)}>
											Выбрать время сессии
										</button>}
									</div>
								</div>
								<div className="team-item-text">
									<p className="team-item-text__subtitle">ОбРазование и квалификация: </p>
									{parse(data.education)}
									{parse(data.qualification)}
								</div>
								<div className="team-item-text">
									<p className="team-item-text__subtitle">Направления работы: </p>
									{parse(data.area)}
								</div>
							</div>
						</section>
						{data.certificates.length > 0 && <section className="about-sert about-sert--team-item container">
							<h3>сертификаты</h3>
							<Lightbox
								slides={data.certificates.map(el => {
									return (
										{ src: el.image }
									)
								})}
								open={galleryIndex >= 0}
								index={galleryIndex}
								close={() => setGalleryIndex(-1)}
							/>
							<div className="main-video-list" id="about_sert_list">
								<Swiper
									modules={[Navigation]}
									spaceBetween={0}
									slidesPerView={5}
									navigation={{
										nextEl: '.swiper-button-next',
										prevEl: '.swiper-button-prev',
									}}
									breakpoints={{
										481: {
											slidesPerView: 3
										},
										769: {
											slidesPerView: 4
										},
										981: {
											slidesPerView: 5
										},
									}}
									loop={false}
									lazy>
									{data.certificates.map((image, idx) => (
										<SwiperSlide key={image.id}>
											<div
												className="slick-slide"
												onClick={() => handleGalleryClick(idx)}>
												<div className="about-sert__item">
													<img src={image.image}
														alt={image.name} />
												</div>
											</div>
										</SwiperSlide>
									))}
								</Swiper>
								<div className="swiper-button-prev"></div>
								<div className="swiper-button-next"></div>
							</div>
						</section>
						}
						<section className="container team-item-schedule">
							{data.schedules.length > 0 && <Schedule data={data.schedules} selected={slotId} setSelected={setSlotId}/>}
							{/* <ConsultForm /> */}
						</section>
					</>
					: <p>Ошибка получения данных</p>
				}
			</LoadingBlock>
			{show && <ModalPopup addedClass="schedule__popup" setShow={setShow}>
				<Schedule 
					data={data.schedules} 
					selected={slotId} 
					setSelected={setSlotId} 
					modal={true} 
					setShow={setShow}/>
			</ModalPopup>}
		</div>
	)
}
export default TeamItemPage;