import HeadTags from "shared/HeadTags";
import { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useGetVideosListQuery } from 'services/api/api';
import { VideosItem } from "services/api/types/News";
import LoadingBlock from 'shared/LoadingBlock';
import ym from 'react-yandex-metrika';


// const videos = [
// 	{
// 		id: '7395d528ab2df8f4a1e94bc985a6a9f8',
// 		name: 'Мифы про психологов',
// 		poster: require("assets/images/mify_pro_psihologov.jpg")
// 	},
// 	{
// 		id: '1c96063f303640ae1b960343d8b76fc1',
// 		name: 'Работа с бесплодием',
// 		poster: require("assets/images/rabota-s-besplodiem.jpg")
// 	},
// 	{
// 		id: '43eee81b0f58725fee40b039fcadfb75',
// 		name: 'Если начальник мало платит',
// 		poster: require("assets/images/esli-malo-platit.jpg")
// 	},
// 	{
// 		id: 'b43c6582170e38676520c6dc9aa3cc2e',
// 		name: 'Если муж не хочет идти к психологу',
// 		poster: require("assets/images/muzh-ne-hochet-k-psihologu.jpg")
// 	},
// 	{
// 		id: '2dee23f812f44555dd0c05dfbb548e7d',
// 		name: 'Не получается построить отношения',
// 		poster: require("assets/images/ne-poluchautsya-otnosheniya.jpg")
// 	},
// 	{
// 		id: 'd1ed8b8d80225245c361178a301f5b89',
// 		name: 'Мама и мужчина',
// 		poster: require("assets/images/mama-i-muzchina.jpg")
// 	},
// 	{
// 		id: 'ff9d6908d4ee97eb0d218985f28bbeea',
// 		name: 'Если муж не слышит',
// 		poster: require("assets/images/muzh-ne-sluishit.jpg")
// 	},
// 	{
// 		id: 'd8fc93805a0c6003b9a4cb5915b9cd1f',
// 		name: 'Где взять ресурс',
// 		poster: require("assets/images/gde-vzyat-resurs.jpg")
// 	}
// ];

const VideoListItem = ({video}: {video: VideosItem}) => {
	// const [play, setPlay] = useState(false);	

	const videoRef = useRef(null);
	// const playVideo = () => {
	// 	setPlay(true);
	// 	videoRef.current.contentWindow.postMessage(JSON.stringify({
	// 		type: 'player:play',
	// 		data: {}
	// 	}), '*');
	// };
	return (
		<li className="video-item">
			<div className="video-item__content">
				<iframe
					src={`${video.link}`}
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					title="Embedded youtube"
					ref={videoRef}
				/>
				{/* {!play && <>
					<button type="button" className="video-item__btn" onClick={playVideo}></button>
				</>} */}
			</div>
			<span className="video-item__name">{video.title}</span>
		</li>
	);
};

const VideoPage = ({ setPageClass }) => {
	const { data, isLoading } = useGetVideosListQuery();
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}, [navigate]);

	useEffect(() => {
		setPageClass('video-page');
		ym('hit', '/video');
		const _tmr = window['_tmr'] || (window['_tmr'] = []);
    	_tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
	}, []);	

	const videosList = data
		? data.length
			? <ul className="video-list">
				{data.map(video => <VideoListItem video={video} key={video.id} />)}
			</ul>
			: null
		: <p>Загрузка ...</p>;

	return (
		<section className="video container">
			<HeadTags
				title='Видео психолога - Елена Полянская'
				metaDescription='Видео психолога Елены Полянской. Видео на самые различные психологические темы.'
			/>
			<ul className="breadcrumbs">
                <li><Link to="/">главная</Link></li>
                <li><span>Видео</span></li>
            </ul>
			<LoadingBlock isLoading={isLoading}>
				<>
					<h1 className="h2">Видео</h1>
					{videosList}
				</>
			</LoadingBlock>			
		</section>
	)

};
export default VideoPage;
